// @flow
import "./auth_form.css";

import classNames from "classnames";
import { Component } from "react";
import * as React from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Markdown from "../markdown";
import DateInput from "./date_input";
import StaffIcon from "./staff_icon";
import type { AuthResponse, AuthType, AuthMessage } from "../../types";
import { getSessionConfig } from "../../services/session_id";

type OwnProps = {
    teamName: string,
    promptMessage: AuthMessage,
    onSubmit: (AuthResponse) => void,
    skipAuthEnabled: boolean,
};

type Props = OwnProps & {
    t: (string) => string,
};

type State = {
    socialLast4: string | null,
    dateOfBirth: string | null,
    token: string | null,
    accessCode: string | null,
    accountNumber: string | null,
    zipCode: string | null,
    billID: string | null,
    cellPhone: string | null,
    email: string | null,
    modified: boolean,
};

type StateUpdate = {
    socialLast4?: string | null,
    dateOfBirth?: string | null,
    token?: string | null,
    accessCode?: string | null,
    accountNumber?: string | null,
    zipCode?: string | null,
    billID?: string | null,
    cellPhone?: string | null,
    email?: string | null,
    modified?: boolean,
};

type AuthFormSubmit = {
    type: AuthType,
    inReplyToMessageID: number,
} & StateUpdate;

type ModifiableState =
    | "socialLast4"
    | "dateOfBirth"
    | "token"
    | "accessCode"
    | "accountNumber"
    | "zipCode"
    | "billID"
    | "cellPhone"
    | "email";

type SyntheticEventHandler = (event: SyntheticEvent<HTMLElement>) => mixed;

const staticDataUrl = (() => {
    const serverDataValue = getSessionConfig("static_url") || "/";
    if (serverDataValue.endsWith("static/")) {
        return serverDataValue;
    }
    return serverDataValue + "static/";
})();

const dateAsString = (date: Date): string => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const dateValidator = (value: ?string): ?string => {
    if (!value) {
        return null;
    }
    const trimmed = value.trim();
    const match = trimmed.match(/^([0-9]{1,2}) *[-\/] *([0-9]{1,2}) *[-\/] *([0-9]{4})$/);

    if (!match) {
        return null;
    } else {
        const language = i18n.language;
        const monthPos = language === "es" ? 2 : 1;
        const dayPos = language === "es" ? 1 : 2;

        const monthPart = Number(match[monthPos]);
        const dayPart = Number(match[dayPos]);
        const yearPart = Number(match[3]);

        if (yearPart < 1900 || yearPart > 2100) {
            return null;
        }
        const dateObj = new Date(yearPart, monthPart - 1, dayPart);
        if (dateObj.getMonth() != monthPart - 1 || dateObj.getDate() != dayPart) {
            return null;
        } else {
            return dateAsString(dateObj);
        }
    }
};

function regexValidator(validRexp: RegExp, trimRexp: ?RegExp) {
    return (rawValue) => {
        if (!rawValue) {
            return null;
        }
        let trimmed = rawValue.trim();
        if (trimRexp) {
            trimmed = trimmed.replace(trimRexp, "");
        }

        if (!trimmed.match(validRexp)) {
            return null;
        } else {
            return trimmed;
        }
    };
}

const fieldValidators = {
    socialLast4: regexValidator(/^[0-9]{4}$/),
    dateOfBirth: dateValidator,
    token: regexValidator(/^[0-9]{6}$/),
    accountNumber: regexValidator(/^[0-9a-zA-Z -]+$/),
    billID: regexValidator(/^[0-9a-zA-Z -]+$/),
    accessCode: regexValidator(/^[0-9]{6}$/),
    zipCode: regexValidator(/^[0-9]{5}$/),
    email: regexValidator(/^[^@]+@.*\..*$/),
    cellPhone: regexValidator(/^1?[0-9]{10}$/, /\(|\)| |\+|-/g),
};

const fieldInputModes = {
    socialLast4: "numeric",
    dateOfBirth: "numeric",
    token: "numeric",
    accountNumber: "numeric",
    billID: "numeric",
    accessCode: "numeric",
    zipCode: "numeric",
    email: "email",
    cellPhone: "tel",
};

const typeFieldsMap: { [AuthType]: Array<ModifiableState> } = {
    AUTH_SOCIAL_DOB: ["socialLast4", "dateOfBirth"],
    AUTH_DOB_ONLY: ["dateOfBirth"],
    AUTH_EMAIL_TOKEN: ["token"],
    AUTH_CELLPHONE_TOKEN: ["token"],
    AUTH_HOMEPHONE_TOKEN: ["token"],
    AUTH_ACCESS_CODE_DOB: ["accessCode", "dateOfBirth"],
    AUTH_BILL_ID_DOB: ["billID", "dateOfBirth"],
    AUTH_ACCOUNT_NUM_ZIP_DOB: ["accountNumber", "zipCode", "dateOfBirth"],
    AUTH_EMAIL_DOB: ["email", "dateOfBirth"],
    AUTH_CELLPHONE_DOB: ["cellPhone", "dateOfBirth"],
    AUTH_TRY_ANOTHER: [],
    AUTH_SKIP: [],
    AUTH_RESEND_TOKEN: [],
};

class AuthForm extends Component<Props, State> {
    handleSubmit: EventHandler;
    formValid: () => boolean;

    constructor() {
        super();
        this.state = {
            dateOfBirth: "",
            socialLast4: null,
            token: null,
            accountNumber: null,
            billID: null,
            accessCode: null,
            zipCode: null,
            email: null,
            cellPhone: null,
            modified: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formValid = this.formValid.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        const { promptMessage } = this.props;
        const formType: AuthType = promptMessage.auth_type;

        // Special cases for secondary submits
        if (e._submitter.dataset.button === "try_another") {
            this.props.onSubmit({
                type: "AUTH_TRY_ANOTHER",
                inReplyToMessageID: this.props.promptMessage.id,
            });
            return;
        } else if (e._submitter.dataset.button === "resend_token") {
            this.props.onSubmit({
                type: "AUTH_RESEND_TOKEN",
                inReplyToMessageID: this.props.promptMessage.id,
            });
            return;
        } else if (e._submitter.dataset.button === "skip_auth") {
            this.props.onSubmit({
                type: "AUTH_SKIP",
                inReplyToMessageID: this.props.promptMessage.id,
            });
            return;
        }

        const fieldsToSubmit = typeFieldsMap[formType];
        const valuesToSubmit = fieldsToSubmit.map((fieldName: ModifiableState) =>
            fieldValidators[fieldName](this.state[fieldName]),
        );

        const formToSubmit = fieldsToSubmit.reduce(
            (prev: AuthFormSubmit, field: ModifiableState, idx) => {
                //$FlowFixMe
                prev[field] = valuesToSubmit[idx];
                return prev;
            },
            { type: formType, inReplyToMessageID: this.props.promptMessage.id },
        );

        //$FlowFixMe
        this.props.onSubmit(formToSubmit);

        this.setState({ modified: false });
    }

    formValid() {
        const { promptMessage } = this.props;
        const formType = promptMessage.auth_type;
        const formFields = typeFieldsMap[formType];
        const formValuesValid = formFields.every(
            (fieldName: ModifiableState) =>
                fieldValidators[fieldName](this.state[fieldName]) !== null,
        );
        return this.state.modified && formValuesValid;
    }

    renderClosedForm(
        titleText: string,
        messageText: string,
        iconPath: string,
        iconClassname: string,
    ) {
        const { t } = this.props;
        let containerClassList = ["message", "received"];

        return (
            <div className={containerClassList.join(" ")}>
                <div className="message--content">
                    <StaffIcon isBot={true} />
                    <div className="auth-form">
                        <div className="auth-form--title">
                            <img src={`${staticDataUrl}media/icons/icon_lock.png`} />
                            {t("secure_area")}
                        </div>
                        <div className="auth-form--closed-title">
                            <img className={iconClassname} src={iconPath} />
                            <div>{titleText}</div>
                        </div>
                        <div className="auth-form--closed-message">{messageText}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderSentLink() {
        const { t } = this.props;
        return this.renderClosedForm(
            t("sent_link"),
            t("link_has_been_sent"),
            `${staticDataUrl}media/icons/circle-check-green.png`,
            "auth-form--verified-check",
        );
    }

    renderVerified() {
        const { t } = this.props;
        return this.renderClosedForm(
            t("verified"),
            t("patient_info_verified"),
            `${staticDataUrl}media/icons/circle-check-green.png`,
            "auth-form--verified-check",
        );
    }

    renderDenied() {
        const { t } = this.props;
        return this.renderClosedForm(
            t("unable_to_verify"),
            t("unable_to_verify_message"),
            `${staticDataUrl}media/icons/icon_alert.svg`,
            "auth-form--denied-icon",
        );
    }

    renderAbandoned() {
        const { t } = this.props;
        return this.renderClosedForm(
            t("canceled"),
            t("verification_canceled_message"),
            `${staticDataUrl}media/icons/icon_alert.svg`,
            "auth-form--denied-icon",
        );
    }

    renderLast4(t, error) {
        const handleChange = (e: SyntheticInputEvent<HTMLElement>): mixed => {
            this.setState({ socialLast4: e.target.value, modified: true });
            return null;
        };

        return (
            <div className="auth-form--input">
                <label className="auth-form--left-label block">
                    {t("patient_ssn_last_4")}
                    <div className="auth-form--input-wrapper">
                        <input
                            onChange={handleChange}
                            className="ssn-last4"
                            type="text"
                            name="ssn_last4"
                            autoComplete="off"
                            placeholder="1234"
                            inputMode="numeric"
                            maxLength="4"
                        />
                        <div className="placeholder">*** - ** -</div>
                        {error ? (
                            <img src={`${staticDataUrl}media/icons/icon_close_black.svg`} />
                        ) : null}
                    </div>
                </label>
            </div>
        );
    }

    renderSimpleInput(
        error: boolean,
        label: string,
        stateVar: ModifiableState,
        name: string,
        placeholder: string,
    ) {
        const handleChange = (e) => {
            let updates: StateUpdate = {
                modified: true,
            };
            const eventValue = e.target.value;
            updates[stateVar] = eventValue;
            this.setState(updates);
        };
        const inputMode = fieldInputModes[stateVar] || "";
        const fieldLength = inputMode === "email" ? "100" : "20";
        return (
            <div className="auth-form--input">
                <label className="block">
                    {label}
                    <div className="auth-form--input-wrapper">
                        <input
                            onChange={handleChange}
                            type="text"
                            placeholder={placeholder}
                            name={name}
                            autoComplete="off"
                            inputMode={inputMode}
                            maxLength={fieldLength}
                        />
                        {error ? (
                            <img src={`${staticDataUrl}media/icons/icon_close_black.svg`} />
                        ) : null}
                    </div>
                </label>
            </div>
        );
    }

    renderOTPToken(t, error) {
        return this.renderSimpleInput(
            error,
            t("sent_verification_code"),
            "token",
            "token",
            t("6-digit code"),
        );
    }

    renderAccessCode(t, error) {
        return this.renderSimpleInput(
            error,
            t("access_code"),
            "accessCode",
            "access_code",
            t("6-digit code"),
        );
    }

    renderAccountNumber(t, error) {
        return this.renderSimpleInput(
            error,
            t("account_number"),
            "accountNumber",
            "account_number",
            t("Account number"),
        );
    }

    renderZipCode(t, error) {
        return this.renderSimpleInput(error, t("zipcode"), "zipCode", "zip_code", t("12345"));
    }

    renderBillID(t, error) {
        return this.renderSimpleInput(error, t("bill_id"), "billID", "bill_id", "0000-0000-0000");
    }

    renderEmail(t, error) {
        return this.renderSimpleInput(error, t("email"), "email", "email", t("user@example.com"));
    }

    renderCellPhone(t, error) {
        return this.renderSimpleInput(
            error,
            t("cell_phone"),
            "cellPhone",
            "cell_phone",
            "(212) 555-1212",
        );
    }

    renderDOB(t, error) {
        const handleChange = (newVal: string) => {
            if (newVal != this.state.dateOfBirth) {
                this.setState({
                    modified: true,
                    dateOfBirth: newVal,
                });
            }
        };

        return (
            <DateInput
                onChange={handleChange}
                className="auth-form--input"
                labelText={t("patient_date_of_birth")}
                placeholder={t("date_format_short")}
                error={error}
            />
        );
    }

    renderErrorBlock(t, error) {
        return error ? <div className="error-block">{t("sorry_unable_to_verify")}</div> : null;
    }

    render() {
        const { t, promptMessage, skipAuthEnabled } = this.props;
        const authStatus = promptMessage.auth_status;
        const error = authStatus === "incorrect" && !this.state.modified;
        const formType = promptMessage.auth_type;

        let containerClassList = ["message", "received"];

        switch (authStatus) {
            case "verified":
                return this.renderVerified();
            case "sent_link":
                return this.renderSentLink();
            case "denied":
                return this.renderDenied();
            case "canceled":
                return this.renderAbandoned();
        }

        if (error) {
            containerClassList.push("error");
        }

        return (
            <div className={containerClassList.join(" ")}>
                <div className="message--content">
                    <StaffIcon isBot={true} />
                    <div className="auth-form">
                        <form onSubmit={this.handleSubmit}>
                            <div className="auth-form--title">
                                <img src={`${staticDataUrl}media/icons/icon_lock.png`} />
                                {t("secure_area")}
                            </div>
                            {!!promptMessage.text ? (
                                <Markdown
                                    className="auth-form--message"
                                    inline={true}
                                    text={promptMessage.text}
                                />
                            ) : null}
                            {formType === "AUTH_SOCIAL_DOB" ? (
                                <div>
                                    {this.renderLast4(t, error)}
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}
                            {formType === "AUTH_EMAIL_TOKEN" ||
                            formType === "AUTH_CELLPHONE_TOKEN" ||
                            formType === "AUTH_HOMEPHONE_TOKEN" ? (
                                <div>
                                    {this.renderOTPToken(t, error)}
                                    {error ? (
                                        <div className="error-block">{t("incorrect_token")}</div>
                                    ) : null}
                                </div>
                            ) : null}
                            {formType === "AUTH_DOB_ONLY" ? (
                                <div>
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}
                            {formType === "AUTH_ACCOUNT_NUM_ZIP_DOB" ? (
                                <div>
                                    {this.renderAccountNumber(t, error)}
                                    {this.renderZipCode(t, error)}
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}
                            {formType === "AUTH_ACCESS_CODE_DOB" ? (
                                <div>
                                    {this.renderAccessCode(t, error)}
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}
                            {formType === "AUTH_BILL_ID_DOB" ? (
                                <div>
                                    {this.renderBillID(t, error)}
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}
                            {formType === "AUTH_EMAIL_DOB" ? (
                                <div>
                                    {this.renderEmail(t, error)}
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}
                            {formType === "AUTH_CELLPHONE_DOB" ? (
                                <div>
                                    {this.renderCellPhone(t, error)}
                                    {this.renderDOB(t, error)}
                                    {this.renderErrorBlock(t, error)}
                                </div>
                            ) : null}

                            {authStatus === "sent_token" ? (
                                <div className="auth-form--token-sent">
                                    {t("token_has_been_sent")}
                                </div>
                            ) : null}
                            <button
                                title={this.formValid() ? "" : t("please_complete_form")}
                                className="button auth-form--submit"
                                disabled={!this.formValid()}
                                onClick={() => {}}
                                data-button="submit"
                            >
                                {t("verify")}
                            </button>
                            {formType === "AUTH_EMAIL_TOKEN" ||
                            formType === "AUTH_CELLPHONE_TOKEN" ||
                            formType === "AUTH_HOMEPHONE_TOKEN" ? (
                                <button
                                    className="button btn-secondary auth-form--submit"
                                    onClick={() => {}}
                                    data-button="resend_token"
                                >
                                    {t("resend_token")}
                                </button>
                            ) : null}
                            <button
                                className={classNames("button btn-cta auth-form--submit", {
                                    "mb-8": !skipAuthEnabled,
                                })}
                                onClick={() => {}}
                                data-button="try_another"
                            >
                                {t("try_another_method")}
                            </button>
                            {skipAuthEnabled ? (
                                <button
                                    className="button btn-cta mb-8 auth-form--submit"
                                    onClick={() => {}}
                                    data-button="skip_auth"
                                >
                                    {t("having_trouble_logging_in")}
                                </button>
                            ) : null}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

AuthForm.displayName = "AuthForm";
const wrappedComponent: React.AbstractComponent<OwnProps> = withTranslation()(AuthForm);
export { dateValidator };
export default wrappedComponent;
