// @flow

import type { ApiShowMessage, Settings } from "./types";
import ReactDOM from "react-dom";
import React from "react";
import Widget from "./components/widget";
import { validateAndLoadDefaultSettings } from "./utils/settings";
import { initI18n } from "./i18n";

type CallFn = (funcString: string, ...args: Array<any>) => void;
type FunctionCall = {
    funcName: string,
    args: Array<any>,
};
type EventHandler = (...args: Array<any>) => void;

// This is used to block website from possible errors in Cedar code. If we throw an error, don't
// let it bubble to them and cause their application to break
function safeFn(fn: Function) {
    return (...args: Array<any>) => {
        try {
            return fn(...args);
        } catch (e) {
            console.error(e);
        }
    };
}

export class ApiHandler {
    static _eventHandlers: { [eventName: string]: Array<EventHandler> };
    static _q: Array<FunctionCall>;
    static _queueCall: CallFn;
    static trigger: (eventName: string, args?: Array<any>) => void;
    static call: CallFn;
    static addApiListener: (callback: CallFn) => void;
    static removeApiListener: () => void;
    static addEventListener: (eventName: string, handler: EventHandler) => void;
    static removeEventListener: (eventName: string, handler: EventHandler) => void;
}

ApiHandler._q = [];
ApiHandler._eventHandlers = {};

/**
 * This function is used to listen to API requests
 * @param callback
 */
ApiHandler.addApiListener = (callback: CallFn) => {
    // If someone called the API before it was ready, make a call for each queued item
    ApiHandler._q.forEach(({ funcName, args }) => callback(funcName, ...args));
    ApiHandler._q = [];

    // Override the call function so handler is called directly
    ApiHandler.call = callback;
};
ApiHandler.removeApiListener = () => {
    ApiHandler.call = ApiHandler._queueCall;
};
ApiHandler.trigger = (eventName, args = []) => {
    if (ApiHandler._eventHandlers[eventName]) {
        ApiHandler._eventHandlers[eventName].forEach((fn) => fn(args));
    }
};
ApiHandler.addEventListener = (eventName: string, handler: EventHandler) => {
    if (!ApiHandler._eventHandlers[eventName]) {
        ApiHandler._eventHandlers[eventName] = [];
    }
    ApiHandler._eventHandlers[eventName].push(handler);
};

ApiHandler.removeEventListener = (eventName: string, handler: EventHandler) => {
    if (ApiHandler._eventHandlers[eventName]) {
        ApiHandler._eventHandlers[eventName] = ApiHandler._eventHandlers[eventName].filter(
            (fn) => fn !== handler,
        );
    }
};

ApiHandler._queueCall = (funcName: string, ...args: Array<any>) => {
    ApiHandler._q.push({
        funcName,
        args,
    });
};
ApiHandler.call = ApiHandler._queueCall;

let _inited = false;
export class PublicApi {
    static open: () => void;
    static close: () => void;
    static on: (eventHandler: string, handler: EventHandler) => void;
    static off: (eventHandler: string, handler: EventHandler) => void;
    static showMessage: (message: ApiShowMessage) => void;
    static init: (settings: Settings) => void;
}
PublicApi.open = safeFn(() => {
    ApiHandler.call("open");
});
PublicApi.close = safeFn(() => {
    ApiHandler.call("close");
});
PublicApi.showMessage = safeFn((message: ApiShowMessage) => {
    ApiHandler.call("showMessage", message);
});
PublicApi.on = safeFn(ApiHandler.addEventListener);
PublicApi.off = safeFn(ApiHandler.removeEventListener);
PublicApi.init = safeFn((settings) => {
    // Don't run init twice
    if (_inited) {
        return;
    }
    _inited = true;
    validateAndLoadDefaultSettings(settings).then((response) => {
        initI18n(settings.lang);
        const {
            chatDomain,
            websocketUrl,
            headerText,
            primaryColor,
            siteSection,
            showLauncher,
            windowRight,
            phoneNumber,
            csrfToken,
            chat_auth__skip_auth_enabled,
            enable_http_endpoint_thread_join_patient,
            enable_http_endpoint_thread_leave_patient,
            enable_http_endpoint_view_chat_patient,
            enable_http_endpoint_loaded_history_patient,
            enable_http_endpoint_submit_chat_patient,
            enable_http_endpoint_user_typing_patient,
        } = response;

        const div = document.createElement("div");
        div.id = "cedar-chat-widget";
        if (document.body) {
            document.body.appendChild(div);
            ReactDOM.render(
                // Write out all settings, so we don't accidentally allow extra props
                React.createElement(Widget, {
                    chatDomain,
                    websocketUrl,
                    headerText,
                    primaryColor,
                    siteSection,
                    showLauncher,
                    windowRight,
                    phoneNumber,
                    csrfToken,
                    chat_auth__skip_auth_enabled,
                    enable_http_endpoint_thread_join_patient,
                    enable_http_endpoint_thread_leave_patient,
                    enable_http_endpoint_view_chat_patient,
                    enable_http_endpoint_loaded_history_patient,
                    enable_http_endpoint_submit_chat_patient,
                    enable_http_endpoint_user_typing_patient,
                }),
                div,
            );
        } else {
            throw new Error("Cedar chat. Unable to find body element. Can't launch.");
        }
    });
});
