// @flow
export type Settings = {|
    chatDomain: string,
    headerText: string,
    primaryColor: string,
    siteSection: string,
    lang: "en" | "es",
    enableNotifications: boolean,
    showLauncher: boolean,
    phoneNumber: string,
    csrfToken: string,
    chat_auth__skip_auth_enabled: boolean,
    enable_http_endpoint_thread_join_patient: boolean,
    enable_http_endpoint_thread_leave_patient: boolean,
    enable_http_endpoint_view_chat_patient: boolean,
    enable_http_endpoint_loaded_history_patient: boolean,
    enable_http_endpoint_submit_chat_patient: boolean,
    enable_http_endpoint_user_typing_patient: boolean,
|};

export type QuickReply = {|
    display_text: string,
    selected?: boolean,
    template_name: string,
    disabled: boolean,
    classes?: Array<string>,
    renderer?: string,
|};

export const WindowStateTypes = {
    NONE: "NONE",
    LOADING: "LOADING",
    READY: "READY",
    ERROR: "ERROR",
};
export type WindowState = $Keys<typeof WindowStateTypes>;

export type RepliableTypes = "FREE_TEXT" | "EMAIL" | "PHONE";

export type FeedbackMessage = {|
    type: "feedback",
    date: number,
|};

export type AuthType =
    | "AUTH_EMAIL_TOKEN"
    | "AUTH_CELLPHONE_TOKEN"
    | "AUTH_HOMEPHONE_TOKEN"
    | "AUTH_SOCIAL_DOB"
    | "AUTH_DOB_ONLY"
    | "AUTH_ACCESS_CODE_DOB"
    | "AUTH_BILL_ID_DOB"
    | "AUTH_ACCOUNT_NUM_ZIP_DOB"
    | "AUTH_EMAIL_DOB"
    | "AUTH_CELLPHONE_DOB"
    | "AUTH_TRY_ANOTHER"
    | "AUTH_RESEND_TOKEN"
    | "AUTH_SKIP";

export type AuthMessage = {|
    type: "auth",
    auth_type: AuthType,
    auth_status:
        | "verified"
        | "sent_link"
        | "sent_token"
        | "denied"
        | "incorrect"
        | "pending"
        | "canceled",
    auth_credentials: AuthResponse | null,
    auth_user_id: string | null,
    date: number,
    id: number,
    in_reply_to_message_id: number | null,
    is_quick_reply: boolean,
    is_staff: boolean,
    message_id: number,
    quick_replies: Array<QuickReply>,
    repliable_type: RepliableTypes,
    repliable_via_text: boolean,
    template_name?: string | null,
    text: string,
    user_id: number,
    user_name: string,
    is_bot: boolean,
|};
export type FullMessage = {|
    type: "full",
    date: number,
    id: number,
    in_reply_to_message_id: number | null,
    is_quick_reply: boolean,
    is_staff: boolean,
    message_id: number,
    quick_replies: Array<QuickReply>,
    repliable_type: RepliableTypes,
    repliable_via_text: boolean,
    template_name?: string | null,
    text: string,
    user_id: number,
    user_name: string,
    is_bot: boolean,
    tempId?: string | null,
|};
export type SentMessage = {|
    type: "sent",
    text: string,
    tempId: string,
    date: number,
    is_quick_reply: boolean,
    template_name?: string | null,
    tempState: string,
    in_reply_to_message_id: number | null,
|};
export type FeedbackResponse = {|
    rating: null | number,
    needsMet: null | boolean,
    comments: string,
|};

type AuthBaseResponse = {
    inReplyToMessageID: number,
};

type AuthBaseDOBResponse = AuthBaseResponse & {
    dateOfBirth: string,
};

export type AuthSocialDOBResponse = AuthBaseDOBResponse & {
    type: "AUTH_SOCIAL_DOB",
    socialLast4: string,
};

export type AuthDOBResponse = AuthBaseDOBResponse & {
    type: "AUTH_DOB_ONLY",
};

export type AuthEmailTokenResponse = AuthBaseDOBResponse & {
    type: "AUTH_EMAIL_TOKEN",
    token: string,
};

export type AuthCellPhoneTokenResponse = AuthBaseDOBResponse & {
    type: "AUTH_CELLPHONE_TOKEN",
    token: string,
};

export type AuthHomePhoneTokenResponse = AuthBaseDOBResponse & {
    type: "AUTH_HOMEPHONE_TOKEN",
    token: string,
};

export type AuthAccessCodeDOBResponse = AuthBaseDOBResponse & {
    type: "AUTH_ACCESS_CODE_DOB",
    accessCode: string,
};

export type AuthBillIdDobResponse = AuthBaseDOBResponse & {
    type: "AUTH_BILL_ID_DOB",
    billID: string,
};

export type AuthAccountNumberZipDOBResponse = AuthBaseDOBResponse & {
    type: "AUTH_ACCOUNT_NUM_ZIP_DOB",
    accountNumber: string,
    zipCode: string,
};

export type AuthTryAnotherResponse = AuthBaseResponse & {
    type: "AUTH_TRY_ANOTHER",
};

export type AuthResendCodeResponse = AuthBaseResponse & {
    type: "AUTH_RESEND_TOKEN",
};

export type AuthEmailDOBResponse = AuthBaseDOBResponse & {
    type: "AUTH_EMAIL_DOB",
    email: string,
};

export type AuthCellPhoneDOBResponse = AuthBaseDOBResponse & {
    type: "AUTH_CELLPHONE_DOB",
    cellPhone: string,
};

export type AuthSkipResponse = AuthBaseResponse & {
    type: "AUTH_SKIP",
};

export type AuthResponse =
    | AuthSocialDOBResponse
    | AuthEmailTokenResponse
    | AuthCellPhoneTokenResponse
    | AuthHomePhoneTokenResponse
    | AuthDOBResponse
    | AuthAccessCodeDOBResponse
    | AuthBillIdDobResponse
    | AuthAccountNumberZipDOBResponse
    | AuthTryAnotherResponse
    | AuthResendCodeResponse
    | AuthEmailDOBResponse
    | AuthCellPhoneDOBResponse
    | AuthSkipResponse;

export type Message = FullMessage | SentMessage | FeedbackMessage | AuthMessage;

export type QuickReplyClickDetails = {|
    text: string,
    is_quick_reply?: boolean | null,
    template_name?: string | null,
    tempId?: string | null,
|};

export type WebsocketChatMessageMessage = {|
    TYPE: "CHAT_MESSAGE",
    date: number,
    id: number,
    in_reply_to_message_id: number | null,
    is_quick_reply: boolean,
    is_staff: boolean,
    message_id: number,
    quick_replies: Array<QuickReply>,
    repliable_type: RepliableTypes,
    repliable_via_text: boolean,
    template_name?: string | null,
    temp_id: string | null,
    text: string,
    user_id: number,
    user_name: string,
    is_bot: boolean,
|};
export type WebsocketChatMessageAck = {|
    TYPE: "ACK_CHAT",
    temp_id: string,
    id: number,
|};
export type WebsocketChatTyping = {|
    TYPE: "USER_TYPING",
    user_id: number,
    user_name: string,
    is_staff: boolean,
    anonymous_thread_id: number,
|};
export type WebsocketClosedThread = {|
    TYPE: "CLOSED_THREAD",
    anonymous_thread_id: number,
    chat_reasons?: Array<string>,
|};

type AttractButton = {|
    display: string,
    onClick: () => void,
|};
export type AttractMessageType = {|
    text: string,
    replies: Array<AttractButton>,
|};

export type WebsocketChatMessage =
    | WebsocketChatMessageAck
    | WebsocketChatMessageMessage
    | WebsocketChatTyping
    | WebsocketClosedThread;

export type ApiShowMessage = {|
    text: string,
    replies: Array<AttractButton>,
|};

export type SessionInfo = {|
    sessionId: string,
    sessionNonce: string,
|};
