import { getCookie, setCookie } from "../utils/cookie";
import xorString from "../utils/xorString";

const BROWSER_SESSION_COOKIE_NAME = "chatSid1";
const TIME_SESSION_COOKIE_NAME = "chatSid2";

function getCookieSessionId(): { sessionId: string, sessionNonce: string } {
    const sessionNonce = getCookie(BROWSER_SESSION_COOKIE_NAME);
    const xorSessionNonceAndId = getCookie(TIME_SESSION_COOKIE_NAME);

    if (!sessionNonce || !xorSessionNonceAndId) {
        return { sessionId: null, sessionNonce: null };
    }
    try {
        // This may throw an error if people modify their cookies manually

        // See setSessionToCookie for why we xor here (this is just the inverse of the set)
        return {
            sessionId: xorString(sessionNonce, atob(xorSessionNonceAndId)),
            sessionNonce: sessionNonce,
        };
    } catch (e) {
        // Create a new session if we couldn't get a valid one from the cookie
        return { sessionId: null, sessionNonce: null };
    }
}

function setSessionToCookie(sessionId: string, sessionNonce: string): void {
    /**
     * For security, split the session ID into 2 cookies.
     *
     * 1 cookie is session based (browser close)
     * 1 cookie is timing based (refreshed on user activity)
     *
     * Combined, the session is deleted if the browser closes or 60 seconds after last activity
     *
     * We store the sessionNonce in the session based cookie and
     * xor(sessionNonce, sessionId) in the timing based cookie. That makes it so you need to
     * xor both cookies to recover the sessionId. If one cookie is removed, the session is not
     * recoverable.
     *
     * DON'T MODIFY this code unless you know what you are doing.
     * This can affect security.
     *
     */
    // Set Cookie expiration to 1 minute
    const timeoutSeconds = 60;
    setCookie(BROWSER_SESSION_COOKIE_NAME, sessionNonce, null);
    setCookie(TIME_SESSION_COOKIE_NAME, btoa(xorString(sessionNonce, sessionId)), timeoutSeconds);
}

export function saveSessionId(sessionId: string | null, sessionNonce: string | null): void {
    if (!sessionId || !sessionNonce) {
        return;
    }
    const { sessionId: cookieSessionId } = getCookieSessionId();
    if (cookieSessionId && cookieSessionId !== sessionId) {
        // There is another tab active that has set the cookie, ignore
        return;
    }
    setSessionToCookie(sessionId, sessionNonce);
}

function serverDataGlobal() {
    const globalDataElement = document.getElementById("_server_data_global");
    if (globalDataElement) {
        return JSON.parse(globalDataElement.text);
    }
    return {};
}

export function getSessionInfo(chatDomain, sessionId): Promise<string> {
    var currentLanguage = "en";

    currentLanguage = serverDataGlobal().current_language || "en";

    return fetch(`https://${chatDomain}/chat/session/${sessionId}?lang=${currentLanguage}`, {
        credentials: "include",
    })
        .then((response) => response.json())
        .then((json) => {
            return json;
        })
        .catch((ex) => {
            console.error("Unable to load session", ex);
            return Promise.reject(ex);
        });
}

export function getSessionId(chatDomain): Promise<string> {
    var currentLanguage = "en";

    currentLanguage = serverDataGlobal().current_language || "en";

    return fetch(`https://${chatDomain}/chat/session/?lang=${currentLanguage}`, {
        credentials: "include",
    })
        .then((response) => response.json())
        .then((json) => {
            return { sessionNonce: json.session_nonce, sessionId: json.session_id };
        })
        .catch((ex) => {
            console.error("Unable to load session", ex);
            return Promise.reject(ex);
        });
}

export function getSessionConfig(varName) {
    return serverDataGlobal()[varName];
}
