// @flow
import "./index.css";

import { Component } from "react";
import * as React from "react";
import MessageList from "./message_list";
import UserInput from "./user_input";
import Header from "./header";
import type {
    AuthResponse,
    Message,
    QuickReplyClickDetails,
    WindowState,
    FeedbackResponse,
} from "../../types";
import Loader from "./loader";
import Error from "./error";
import { WindowStateTypes } from "../../types";
import { Transition } from "react-transition-group";

const slideFromRightDuration = 500;
const slideFromRightClasses = {
    entering: "entered",
    entered: "entered",
    exiting: "exited",
    exited: "exited",
};

type Props = {
    isOpen: boolean,
    focusInput: boolean,
    messages: Array<Message>,
    agentProfile: {
        teamName: string,
        imageUrl: string,
        phoneNumber: string,
    },
    usersTyping: string[] | null,
    windowRight: boolean,
    connected: boolean,
    state: WindowState,
    onInputFocused: () => void,
    onClose: () => void,
    onUserInputSubmit: (message: QuickReplyClickDetails) => void,
    onTyping: () => void,
    onRestart: () => void,
    onFeedbackSubmit: (feedback: FeedbackResponse) => void,
    onAuthSubmit: (credentials: AuthResponse) => void,
    skipAuthEnabled: boolean,
};

class WindowContents extends Component<Props> {
    input: HTMLInputElement;
    setInputRef: Function;

    constructor() {
        super();

        this.setInputRef = this.setInputRef.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) ||
            (this.props.focusInput && !prevProps.focusInput)
        ) {
            setTimeout(() => {
                if (this.input) {
                    this.input.focus();

                    if (this.props.focusInput) {
                        this.props.onInputFocused();
                    }
                }
            }, 100);
        }
    }

    setInputRef(el: HTMLInputElement) {
        this.input = el;
    }

    renderMessages(): ?React.Node {
        if (this.props.state === WindowStateTypes.READY) {
            return (
                <MessageList
                    teamName={this.props.agentProfile.teamName}
                    messages={this.props.messages}
                    imageUrl={this.props.agentProfile.imageUrl}
                    usersTyping={this.props.usersTyping}
                    onSendMessage={this.props.onUserInputSubmit}
                    onFeedbackSubmit={this.props.onFeedbackSubmit}
                    onAuthSubmit={this.props.onAuthSubmit}
                    skipAuthEnabled={this.props.skipAuthEnabled}
                />
            );
        }

        if (this.props.state === WindowStateTypes.LOADING) {
            return <Loader />;
        }

        if (this.props.state === WindowStateTypes.ERROR) {
            return <Error onClick={this.props.onRestart} />;
        }
    }

    render(): React.Node {
        let { messages } = this.props;
        const latestMessage = messages.length ? messages[messages.length - 1] : null;
        return (
            <div className="chat-window">
                <Header
                    teamName={this.props.agentProfile.teamName}
                    imageUrl={this.props.agentProfile.imageUrl}
                    phoneNumber={this.props.agentProfile.phoneNumber}
                    onClose={this.props.onClose}
                    isOpen={this.props.isOpen}
                />
                {this.renderMessages()}
                <UserInput
                    connected={this.props.connected}
                    latestMessage={latestMessage}
                    inputRef={this.setInputRef}
                    loaded={this.props.state === WindowStateTypes.READY}
                    onSubmit={this.props.onUserInputSubmit}
                    onTyping={this.props.onTyping}
                />
            </div>
        );
    }
}

class ChatWindow extends Component<Props> {
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown: (e: KeyboardEvent) => void = (e: KeyboardEvent) => {
        if (!this.props.isOpen || e.key !== "Tab") return;

        const chatWidget = document.getElementById("cedar-chat-widget");
        if (!chatWidget) return;

        const focusableElements = chatWidget.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.shiftKey) {
            if (
                document.activeElement === firstElement ||
                !chatWidget.contains(document.activeElement)
            ) {
                e.preventDefault();
                lastElement.focus();
            }
        } else {
            if (
                document.activeElement === lastElement ||
                !chatWidget.contains(document.activeElement)
            ) {
                e.preventDefault();
                firstElement.focus();
            }
        }
    };

    render(): React.Node {
        if (this.props.windowRight) {
            return (
                <Transition in={this.props.isOpen} timeout={slideFromRightDuration}>
                    {(state) => (
                        <div
                            className={`window-container window-right ${
                                slideFromRightClasses[state]
                            } ${state === "exited" ? "invisible" : ""}`}
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="chat-team-name"
                        >
                            <WindowContents {...this.props} />
                        </div>
                    )}
                </Transition>
            );
        }

        return (
            <div className={`window-container ${this.props.isOpen ? "" : "invisible"}`}>
                <WindowContents {...this.props} />
            </div>
        );
    }
}

ChatWindow.displayName = "ChatWindow";

export default ChatWindow;
