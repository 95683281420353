// @flow

import "./user_input.css";
import throttle from "lodash.throttle";
import { Component } from "react";
import * as React from "react";
import { withTranslation } from "react-i18next";
import type { Message, QuickReplyClickDetails } from "../../types";

const SendIcon = ({ disabled }: { disabled: boolean }) => (
    <button
        disabled={disabled}
        type="submit"
        aria-label="Send Message"
        className="user-input--send-icon-wrapper"
    >
        <svg width="21px" height="21px" viewBox="0 0 21 21">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Components---Icons"
                    transform="translate(-450.000000, -2642.000000)"
                    fillRule="nonzero"
                    stroke="#3667E9"
                    strokeWidth="0.2"
                >
                    <g
                        id="Icon-/-Actionable-/-Send-/-Active"
                        transform="translate(451.000000, 2643.000000)"
                    >
                        <path
                            d="M17.3539332,3.48166685 L8.9217713,11.9138287 L12.0226308,17.5171363 L17.3539332,3.48166685 Z M7.9425525,10.9346099 L16.3747144,2.50244805 L2.33924491,7.83375041 L7.9425525,10.9346099 Z M18.9315635,0.0544010445 C19.4755739,-0.163203134 20.0195844,0.380807312 19.8019802,0.924817757 L12.7842455,19.3667718 C12.6210423,19.9107823 11.8594277,19.9651833 11.5874225,19.4755739 L7.56174519,12.2946361 L0.435208356,8.32335981 C-0.108802089,8.05135459 -0.0544010445,7.28973996 0.489609401,7.07213579 L18.9315635,0.0544010445 Z"
                            id="Shape"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </button>
);

type State = {
    text: string,
};

type OwnProps = {
    latestMessage: Message | null,
    inputRef: (el: HTMLTextAreaElement | null) => void,
    loaded: boolean,
    connected: boolean,
    onSubmit: (message: QuickReplyClickDetails) => void,
    onTyping: () => void,
};
type Props = OwnProps & {
    t: (string) => string,
};

class UserInput extends Component<Props, State> {
    handleSubmit: Function;
    handleChange: Function;
    handleKey: Function;
    throttleTyping: Function;

    constructor() {
        super();
        this.state = {
            text: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.throttleTyping = throttle(
            () => {
                this.props.onTyping();
            },
            5000,
            {
                leading: true,
                trailing: false,
            },
        );
    }

    handleSubmit(event: Event) {
        event.preventDefault();

        // Remove extra whitespace
        const text = this.state.text.replace(/\s\s+/g, " ").trim();
        if (text === "" || !this.props.connected) {
            return;
        }
        this.props.onSubmit({
            text,
        });
        this.setState({
            text: "",
        });
    }

    handleKey(event: SyntheticKeyboardEvent<HTMLTextAreaElement>) {
        if (event.keyCode === 13 && !event.shiftKey) {
            this.handleSubmit(event);
        } else {
            this.throttleTyping();
        }
    }

    handleChange(ev: SyntheticInputEvent<HTMLTextAreaElement>) {
        this.setState({
            text: ev.target.value,
        });
    }

    getPlaceHolder() {
        const { latestMessage, t } = this.props;

        if (!this.props.connected) {
            return t("please_connect");
        }

        if (latestMessage && latestMessage.repliable_type) {
            switch (latestMessage.repliable_type) {
                case "EMAIL":
                    return t("enter_email");
                case "PHONE":
                    return "(555) 555-5555";
            }
        }

        return t("write_a_message");
    }
    showTextInput() {
        const { latestMessage, loaded } = this.props;
        return (
            loaded &&
            latestMessage != null &&
            (latestMessage.repliable_via_text == null || latestMessage.repliable_via_text !== false)
        );
    }

    render() {
        return (
            <form
                className={`user-input ${this.showTextInput() ? "" : "hide"}`}
                onSubmit={this.handleSubmit}
            >
                <textarea
                    rows="1"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKey}
                    ref={this.props.inputRef}
                    data-test="chat-input"
                    placeholder={this.getPlaceHolder()}
                    className="user-input--text"
                    value={this.state.text}
                    disabled={!this.showTextInput() || !this.props.connected}
                    title={this.getPlaceHolder()}
                />
                <div className="user-input--buttons">
                    <div className="user-input--button">
                        <SendIcon disabled={!this.props.connected || !this.state.text} />
                    </div>
                </div>
            </form>
        );
    }
}

const translatedComponent: React.AbstractComponent<OwnProps> = withTranslation()(UserInput);
export default translatedComponent;
