import React from "react";

export default function Loader() {
    return (
        <div className="window-loading">
            <div className="loading-dot loading-dot-1" />
            <div className="loading-dot loading-dot-2" />
            <div className="loading-dot loading-dot-3" />
        </div>
    );
}
