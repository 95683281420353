// @flow
//
// The <DateInput /> handles date inputs using an input mask
// widget
//
// It wants to build a date in the "MM/DD/YYYY" or "DD/MM/YYYY"
// format.
//
// The widget doesn't know about i18n, but the value sent to onChange is
// just a string so there are no assumptions about the meaning of the
// fields beyond that the format is XX / XX / XXXX.  So if you i18n the
// placeholder and whatever onChange handler you provide it is fine
// for either "MM / DD / YYYY" or "DD / MM / YYYY".
//
// Styling: You can pass in a class name as the className prop, which
// is applied to the top-level div. There are 3 placeholder classes to
// support more detailed customization.

// For now, we are using the Inputmask library since that's what
// the other date entry in the patient app is using, but its
// behavior is pretty subpar so I think we will upgrade.

import { Component } from "react";
import * as React from "react";
import Inputmask from "inputmask";

type Props = {
    onChange: (string) => void,
    error: boolean,
    labelText: string,
    placeholder: string,
    className?: string,
    initialValue?: string,
};

type State = {
    lastValue: ?string,
};

class DateInput extends Component<Props, State> {
    inputElement: any;
    handleChange: (SyntheticInputEvent<HTMLInputElement>) => string;

    constructor() {
        super();
        this.inputElement = React.createRef();
        this.state = {
            lastValue: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: SyntheticInputEvent<HTMLInputElement>): string {
        const newVal = e.target.value;

        if (newVal !== this.state.lastValue) {
            if (newVal !== null && this.props.onChange) {
                this.props.onChange(newVal);
            }

            let updates = {
                lastValue: newVal,
            };
            this.setState(updates);
        }
        return newVal;
    }

    componentDidUpdate() {
        if (this.props.initialValue && this.state.lastValue === null) {
            this.setState({ lastValue: this.props.initialValue });
        }
    }

    componentDidMount() {
        if (this.inputElement.current) {
            Inputmask("99/99/9999", {
                placeholder: " ",
                greedy: false,
                jitMasking: true,
                showMaskOnFocus: false,
                showMaskOnHover: false,
            }).mask(this.inputElement.current);
        }

        if (this.props.initialValue && this.state.lastValue === null) {
            this.setState({ lastValue: this.props.initialValue });
        }
    }

    render(): React.Node {
        // Filter and format the displayed date string on change
        const displayValue = this.state.lastValue || "";

        return (
            <div className={this.props.className || ""}>
                <label className="block date-input--label">
                    {this.props.labelText}
                    <div className="date-input--input-wrapper">
                        <input
                            ref={this.inputElement}
                            onChange={this.handleChange}
                            placeholder={this.props.placeholder}
                            autoComplete="off"
                            inputMode="numeric"
                            size={20}
                        />
                        {this.props.error ? (
                            <img src="/static/media/icons/icon_close_black.svg" />
                        ) : null}
                    </div>
                </label>
            </div>
        );
    }
}
export default DateInput;
