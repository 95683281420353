import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/*
If you add keys here, make sure to add keys for all languages, not just english
 */
export const i18nRawValues = {
    resources: {
        en: {
            translation: {
                help: "Help",
                sending: "Sending...",
                failed_to_send: "Failed to send. Click to retry",
                write_a_message: "Enter a message...",
                enter_email: "Enter email...",
                error_loading_chat:
                    "There was an error loading the chat. Please check your connection and click Try again.",
                please_connect: "Please connect to the internet",
                try_again: "Try again",
                typing: "Typing...",
                second: "{{count}} second ago",
                second_plural: "{{count}} seconds ago",
                minute: "{{count}} minute ago",
                minute_plural: "{{count}} minutes ago",
                hour: "{{count}} hour ago",
                hour_plural: "{{count}} hours ago",
                day: "{{count}} day ago",
                day_plural: "{{count}} days ago",
                month: "{{count}} month ago",
                month_plural: "{{count}} months ago",
                year: "{{count}} year ago",
                year_plural: "{{count}} years ago",
                how_was_your_chat_experience: "How was your chat experience?",
                were_all_of_your_needs_met: "Were all of your needs met?",
                yes: "Yes",
                no: "No",
                comments: "Comments",
                please_complete_form: "Please complete form",
                submit: "Submit",
                close: "Close chat window",
                what_could_we_have_done_better: "What could we have done better?",
                thank_you_for_your_feedback: "Thank you for your feedback!",
                were_sorry_your_needs_werent_met:
                    " We're sorry your needs were not met. We will use this feedback to improve our process. Thank you.",
                secure_area: "Secure area",
                patient_ssn_last_4: "Patient's SSN (Last 4 digits)",
                patient_date_of_birth: "Patient's date of birth",
                account_number: "Account number",
                bill_id: "Bill ID",
                zipcode: "ZIP code",
                verify: "Verify",
                try_another_method: "Try another method",
                having_trouble_logging_in: "I'm having trouble signing in",
                email: "Email address",
                cell_phone: "Phone number",
                verification_code: "Verification code",
                access_code: "Access code",
                resend_token: "Resend token",
                sorry_unable_to_verify:
                    "Sorry, we were unable to match the information you provided with what we have on file. Please try again, or choose another verification option.",
                incorrect_token:
                    "The verification code you entered is incorrect. Please try again.",
                verified: "Verified",
                sent_link: "Sent link",
                link_has_been_sent: "A login link for the account has been sent.",
                patient_info_verified: "The patient's information has been verified.",
                unable_to_verify: "Unable to verify personal information",
                unable_to_verify_message:
                    "Sorry, we're having trouble confirming your identity. We can connect you to our support team to help update and verify your information.",
                sent_verification_code: "Please enter the 6-digit code",
                token_has_been_sent: "A new code has been sent.",
                canceled: "Canceled",
                verification_canceled_message:
                    "This method of identity confirmation has been canceled",
                date_format_short: "MM / DD / YYYY",
                just_now: "Just now",
            },
        },
        es: {
            translation: {
                help: "Ayudar",
                sending: "Enviando...",
                failed_to_send: "Error al enviar. Haga clic para volver a intentarlo.",
                write_a_message: "Escribe un mensaje...",
                enter_email: "Ingrese su correo electrónico...",
                error_loading_chat:
                    "Hubo un error al cargar el chat. Por favor, compruebe su conexión y haga clic en Inténtalo de nuevo",
                please_connect: "Por favor conectarse a Internet",
                try_again: "Inténtalo de nuevo",
                typing: "Está escribiendo...",
                second: "Hace {{count}} segundo",
                second_plural: "Hace {{count}} segundos",
                minute: "Hace {{count}} minuto",
                minute_plural: "Hace {{count}} minutos",
                hour: "Hace {{count}} hora",
                hour_plural: "Hace {{count}} horas",
                day: "Hace {{count}} dia",
                day_plural: "Hace {{count}} dias",
                month: "Hace {{count}} mes",
                month_plural: "Hace {{count}} meses",
                year: "Hace {{count}} año",
                year_plural: "Hace {{count}} años",
                how_was_your_chat_experience: "¿Cómo fue tu experiencia de chat?",
                were_all_of_your_needs_met: "¿Se cumplieron todas sus necesidades?",
                yes: "Si",
                no: "No",
                comments: "Comentarios",
                please_complete_form: "Por favor complete el formulario",
                submit: "Enviar",
                close: "Cerrar la ventana de conversación",
                what_could_we_have_done_better: "¿Qué podríamos haber hecho mejor?",
                thank_you_for_your_feedback: "¡Gracias por tus comentarios!",
                were_sorry_your_needs_werent_met:
                    "Lamentamos que no se hayan satisfecho sus necesidades. Utilizaremos estos comentarios para mejorar nuestro proceso. Gracias.",

                // need translations before release
                secure_area: "Área segura",
                patient_ssn_last_4: "SSN del paciente (últimos 4 dígitos)",
                patient_date_of_birth: "Fecha de nacimiento del paciente",
                account_number: "Número de cuenta",
                bill_id: "ID de la factura",
                zipcode: "Código postal",
                verify: "Verificar",
                try_another_method: "Intentar con otro método",
                email: "Dirección de correo electrónico",
                cell_phone: "Número de teléfono",
                verification_code: "Código de verificación",
                access_code: "Código de acceso",
                resend_token: "Reenviar token",
                sorry_unable_to_verify:
                    "Lo sentimos, no pudimos encontrar la información que proporcionó en el archivo. Vuelva a intentarlo o elija otra opción de verificación.",
                incorrect_token:
                    "El código de verificación que ingresó es incorrecto. Vuelva a intentarlo.",
                verified: "Verificado",
                sent_link: "Enlace enviado",
                link_has_been_sent: "Se envió un enlace para iniciar sesión en la cuenta.",
                patient_info_verified: "Se verificó la información del paciente.",
                unable_to_verify: "No se pudo verificar la información personal",
                unable_to_verify_message:
                    "Lo sentimos, no pudimos confirmar su identidad. Podemos conectarse con el equipo de asistencia para ayudarle a actualizar y verificar su información.",
                sent_verification_code: "Ingrese el código de 6 dígitos",
                token_has_been_sent: "Se envió un código nuevo.",
                canceled: "Cancelado",
                verification_canceled_message:
                    "Se canceló este método de confirmación de identidad",
                date_format_short: "DD/MM/AAAA",
                just_now: "Justo ahora",
                having_trouble_logging_in: "Tengo problemas para iniciar sesión",
            },
        },
    },
    defaultTransParent: "div",

    interpolation: {
        escapeValue: false,
    },
};

export function initI18n(lang) {
    i18n.use(initReactI18next).init({
        ...i18nRawValues,
        lng: lang || "en",
    });
}

export default i18n;
