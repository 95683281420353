// @flow

import classNames from "classnames";
import "./message.css";
import { Component } from "react";
import * as React from "react";
import QuickReply from "./quick_reply";
import StaffIcon from "./staff_icon";
import Markdown from "../markdown";
import { timeAgo } from "../../utils/time_ago";
import { MessageState } from "../../enums";
import { withTranslation } from "react-i18next";
import type { FullMessage, SentMessage, AuthMessage, QuickReplyClickDetails } from "../../types";
type MessageType = FullMessage | SentMessage | AuthMessage;
import { marked } from "marked";
import DOMPurify from "dompurify";

type OwnProps = {
    message: MessageType,
    onSendMessage: (message: QuickReplyClickDetails) => void,
    showTime: boolean,
    teamName: string,
};
type Props = OwnProps & {
    t: (string) => string,
};

class Message extends Component<Props> {
    handleClick: Function;

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        // Only allow resending on failed messages
        if (this.props.message.tempState !== MessageState.FAILED) {
            return;
        }

        const message = this.props.message;

        let tempId = null;
        if (message.tempId != null) {
            tempId = message.tempId;
        }

        this.props.onSendMessage({
            text: message.text,
            tempId: tempId,
        });
    }
    getFooterText(message: MessageType) {
        const { t } = this.props;

        if (message.tempState === MessageState.FAILED) {
            return t("failed_to_send");
        }
        if (message.tempState === MessageState.LOADING) {
            return t("sending");
        }
        if (this.props.showTime) {
            return timeAgo(message.date);
        }

        return "";
    }

    renderQuickReplies(message: MessageType) {
        if (message.quick_replies && message.quick_replies.length) {
            const replyList = message.quick_replies
                .filter((quickReply) => {
                    const cls = quickReply.classes || [];
                    const hideWhenSelected = cls.indexOf("hide-on-select") >= 0;
                    const showBecauseSelected =
                        quickReply.selected && cls.indexOf("show-on-select") >= 0;
                    const hideWhenDisabled =
                        cls.indexOf("hide-on-disable") >= 0 && !showBecauseSelected;
                    if (quickReply.selected && hideWhenSelected) {
                        return false;
                    } else if (quickReply.disabled && hideWhenDisabled) {
                        return false;
                    }
                    return true;
                })
                .map((quickReply) => (
                    <QuickReply quickReply={quickReply} onClick={this.props.onSendMessage} />
                ));

            if (!replyList.length) {
                return null;
            }

            return (
                <ul className="message--quick-reply-buttons" role="list">
                    {replyList.map((quickReply, index) => (
                        <li
                            className="message--quick-reply-container"
                            role="listitem"
                            key={"qr-" + index}
                        >
                            {quickReply}
                        </li>
                    ))}
                </ul>
            );
        }

        return null;
    }

    render() {
        const { message } = this.props;
        const failedOrLoading =
            message.type == "sent" &&
            message.tempState != null &&
            [MessageState.FAILED, MessageState.LOADING].indexOf(message.tempState) > -1;
        const hasQuickReplies = message.quick_replies && message.quick_replies.length;
        const isStaff = (message.type === "full" || message.type === "auth") && message.is_staff;
        const isBot = (message.type === "full" || message.type === "auth") && message.is_bot;
        let containerClassList = classNames("message", {
            received: isStaff,
            sent: !isStaff,
            loading: message.type === "sent" && message.tempState === MessageState.LOADING,
            failed: message.type === "sent" && message.tempState === MessageState.FAILED,
        });
        const quickReplies = this.renderQuickReplies(message);

        // hide quick reply messages if there are no choices
        // (this is really just for the "connect to advocate" form,
        // where canceling is the only option)
        if (hasQuickReplies && !quickReplies) {
            return null;
        }

        return (
            <div className={containerClassList}>
                <div className="message--content" onClick={this.handleClick}>
                    {isStaff ? (
                        <StaffIcon isBot={isBot} userName={message.user_name || ""} />
                    ) : null}
                    <div
                        className={classNames("message--inner", {
                            "message--customer": !isStaff,
                            "message--customer-light": failedOrLoading,
                            "message--quick-replies": hasQuickReplies,
                        })}
                    >
                        {message.text ? (
                            <Markdown className="message--text" inline={true} text={message.text} />
                        ) : null}
                        {quickReplies}
                    </div>
                </div>
                <div className="message--footer">{this.getFooterText(message)}</div>
            </div>
        );
    }
}
Message.displayName = "Message";

const translatedComponent: React.AbstractComponent<OwnProps> = withTranslation()(Message);
export default translatedComponent;
