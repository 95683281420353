// polyfill for event.submitter (not supported in Safari
// macOS or iOs)
//
// note that Safari has ~recently started enforcing the "read-only"
// nature of event.submitter and will not allow this polyfill to
// set it. Since we just use it in one place, it's ok to set the
// submitter as e._submitter instead and refer to that in the code
// that needs it.
//
// polyfill from https://gist.github.com/nuxodin/3ae174f2a6a112df3ccad22459237a91

!(function () {
    var lastBtn = null;
    document.addEventListener(
        "click",
        function (e) {
            if (!e.target.closest) return;
            lastBtn = e.target.closest("button, input[type=submit]");
        },
        true,
    );
    document.addEventListener(
        "submit",
        function (e) {
            if (e.submitter) {
                e._submitter = e.submitter;
                return;
            }

            var candidates = [document.activeElement, lastBtn];
            for (var i = 0; i < candidates.length; i++) {
                var candidate = candidates[i];
                if (!candidate) continue;
                if (!candidate.form) continue;
                if (!candidate.matches("button, input[type=button], input[type=image]")) continue;
                e._submitter = candidate;
                return;
            }
            e._submitter = e.target.querySelector("button, input[type=button], input[type=image]");
        },
        true,
    );
})();
