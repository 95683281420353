import React from "react";
import { useTranslation } from "react-i18next";
import { getInitials } from "../../utils/names";

export default function Typing({ name }: { name: string }) {
    const { t } = useTranslation();
    const initials = getInitials(name);

    return (
        <div className="message received">
            <div className="message--content">
                <div className="message--badge-cont">
                    <div className="message--badge message--badge-human">
                        <div className="message--badge-text">{initials}</div>
                        <div className="message--badge-live" />
                    </div>
                </div>
                <div className="message--inner">
                    <div className="message--text">
                        <div className="typing">
                            <div className="typing-dot typing-dot-1" />
                            <div className="typing-dot typing-dot-2" />
                            <div className="typing-dot typing-dot-3" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="message--footer">{t("typing")}</div>
        </div>
    );
}
