function setCookieVal(cookieVal: string): void {
    document.cookie = cookieVal;
}

export function setCookie(
    name: string,
    value: string,
    timeoutSeconds: null | number = null,
    setCookieFn: (cookie: string) => void = setCookieVal,
): void {
    let cookieValue = `${name}=${value}; path=/; secure; SameSite=Strict;`;
    if (timeoutSeconds != null) {
        cookieValue += ` max-age=${timeoutSeconds};`;
    }
    setCookieFn(cookieValue);
}

export function deleteCookie(name): void {
    setCookie(`${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
}

export function getCookie(name: string, cookieSource: string = document.cookie): null | string {
    const v = cookieSource.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
}
