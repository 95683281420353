export const getInitials = (name: string = "") => {
    const firstLast = name.split(" ");
    let initials = firstLast[0].substring(0, 1).toUpperCase();

    // Add a last initial, if there is a last name
    if (firstLast.length > 1) {
        initials += firstLast[firstLast.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};
