import React from "react";
import { useTranslation } from "react-i18next";

export default function Error(props) {
    const { t } = useTranslation();

    return (
        <div className="error-window">
            <div className="error-message">
                <div aria-role="alert" id="error-message-text">
                    {t("error_loading_chat")}
                </div>
                <div className="error-button-cont">
                    <button
                        className="button"
                        onClick={props.onClick}
                        disabled={false}
                        selected={false}
                        aria-describedby="error-message-text"
                    >
                        {t("try_again")}
                    </button>
                </div>
            </div>
        </div>
    );
}
