import { Component } from "react";
import * as React from "react";
import { getInitials } from "../../utils/names";

type Props = {
    isBot?: boolean,
    userName?: string,
};

export default class StaffIcon extends Component<Props> {
    render() {
        const { isBot, userName } = this.props;
        const iconSize = 30;

        if (isBot) {
            return (
                <div className="message--badge-cont">
                    <svg
                        width={iconSize}
                        height={iconSize}
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        aria-label="Billing assistant"
                    >
                        <title>Billing assistant</title>
                        <circle cx="15" cy="15" r="15" fill="#E4EDFE" />
                        <path
                            d="M9.98364 18.9076L10.3054 18.2301L10.3026 18.2288L9.98364 18.9076ZM10.6364 19.2176H11.3864V18.7436L10.9582 18.5402L10.6364 19.2176ZM10.6364 21.8H9.88636C9.88636 22.0992 10.0642 22.3698 10.3389 22.4885C10.6136 22.6072 10.9325 22.5512 11.1504 22.3461L10.6364 21.8ZM12.8818 19.6866L13.3959 20.2328L13.4035 20.2255L12.8818 19.6866ZM13.1818 19.3962V18.6462H12.8783L12.6602 18.8573L13.1818 19.3962ZM17.8327 8.25H12.1673V9.75H17.8327V8.25ZM12.1673 8.25C8.89984 8.25 6.25 10.9211 6.25 14.1981H7.75C7.75 11.7413 9.73652 9.75 12.1673 9.75V8.25ZM6.25 14.1981C6.25 16.4969 7.58771 18.6106 9.66472 19.5864L10.3026 18.2288C8.75411 17.5013 7.75 15.9181 7.75 14.1981H6.25ZM9.66185 19.585L10.3146 19.8951L10.9582 18.5402L10.3054 18.2301L9.66185 19.585ZM9.88636 19.2176V21.8H11.3864V19.2176H9.88636ZM11.1504 22.3461L13.3958 20.2328L12.3678 19.1405L10.1223 21.2539L11.1504 22.3461ZM13.4035 20.2255L13.7035 19.9351L12.6602 18.8573L12.3602 19.1478L13.4035 20.2255ZM13.1818 20.1462H17.8327V18.6462H13.1818V20.1462ZM17.8327 20.1462C21.1002 20.1462 23.75 17.4751 23.75 14.1981H22.25C22.25 16.6549 20.2635 18.6462 17.8327 18.6462V20.1462ZM23.75 14.1981C23.75 10.9211 21.1002 8.25 17.8327 8.25V9.75C20.2635 9.75 22.25 11.7413 22.25 14.1981H23.75Z"
                            fill="#3667E9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.2082 15.3995C11.7206 15.4028 11.2795 15.1108 11.0921 14.6606C10.9048 14.2104 11.0085 13.6916 11.3545 13.348C11.7005 13.0045 12.22 12.9044 12.6689 13.0949C13.1177 13.2854 13.4067 13.7286 13.3999 14.2162C13.3897 14.8677 12.8598 15.391 12.2082 15.3932V15.3995Z"
                            fill="#3667E9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.1895 15.3994C16.5274 15.3937 15.995 14.8526 16 14.1905C16.005 13.5283 16.5454 12.9953 17.2076 12.9995C17.8698 13.0037 18.4034 13.5435 18.4 14.2057C18.3864 14.8648 17.8487 15.3922 17.1895 15.3932V15.3994Z"
                            fill="#3667E9"
                        />
                        <path
                            d="M25.5 13V13C25.8246 13.9737 25.8246 15.0263 25.5 16V16"
                            stroke="#3667E9"
                            stroke-linecap="round"
                        />
                        <path
                            d="M4.5 16V16C4.17544 15.0263 4.17544 13.9737 4.5 13V13"
                            stroke="#3667E9"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
            );
        }

        let initials = "";
        if (userName) {
            initials = getInitials(userName);
        }

        return (
            <div
                role="img"
                alt="Billing assistant"
                className="message--badge message--badge-cont message--badge-human"
            >
                <div className="message--badge-text">{initials}</div>
                <div className="message--badge-live" />
            </div>
        );
    }
}
