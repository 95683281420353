// @flow
export default function xorString(a: string, b: string): string {
    /**
     * This xors 2 strings by getting the character code for each character
     * and xoring the 2 numbers. It then turns that number into a string character
     * via fromCharCode.
     *
     * Notice: This may return unicode characters that are not visible
     * (look like spaces to the naked eye).
     *
     */

    if (a.length !== b.length) {
        throw new Error("Both strings must be of equal length");
    }

    let c = "";
    for (let i = 0; i < a.length; i++) {
        const char = String.fromCharCode(a[i].charCodeAt(0) ^ b[i].charCodeAt(0));
        c += char;
    }
    return c;
}
