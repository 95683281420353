import type { Settings } from "../types";
import axios from "axios";

export function validateAndLoadDefaultSettings(settings: Settings) {
    return fetch(`/home/endpoint-model-settings/`, {
        credentials: "include",
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response;
        })
        .then((response) => response.json())
        .then((response_json) => {
            if (!settings) {
                throw new Error(
                    "Unable to get CedarSettings. Please set CedarSettings to enable chat.",
                );
            }
            settings.enable_http_endpoint_thread_join_patient =
                response_json.enable_http_endpoint_thread_join_patient;
            settings.enable_http_endpoint_thread_leave_patient =
                response_json.enable_http_endpoint_thread_leave_patient;
            settings.enable_http_endpoint_view_chat_patient =
                response_json.enable_http_endpoint_view_chat_patient;
            settings.enable_http_endpoint_loaded_history_patient =
                response_json.enable_http_endpoint_loaded_history_patient;
            settings.enable_http_endpoint_submit_chat_patient =
                response_json.enable_http_endpoint_submit_chat_patient;
            settings.enable_http_endpoint_user_typing_patient =
                response_json.enable_http_endpoint_user_typing_patient;
            settings.chat_auth__skip_auth_enabled =
                response_json.chat_auth__skip_auth_enabled || false;

            if (!settings.chatDomain) {
                throw new Error(
                    'Unable to get chatDomain. Please set CedarSettings = {chatDomain: "YOUR_DOMAIN",' +
                        ' headerText: "YOUR_COMPANY_NAME" }; to enable chat.',
                );
            }

            if (!settings.headerText) {
                throw new Error(
                    'Unable to get headerText. Please set CedarSettings = {chatDomain: "YOUR_DOMAIN",' +
                        ' headerText: "YOUR_COMPANY_NAME" }; to enable chat.',
                );
            }

            if (
                settings.siteSection &&
                (typeof settings.siteSection !== "string" || settings.siteSection.length > 100)
            ) {
                throw new Error(
                    "siteSection must be a string that is between 1 and 100 characters",
                );
            }

            if (settings.primaryColor) {
                if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(settings.primaryColor)) {
                    throw new Error(
                        "Invalid primaryColor. It must be a valid hex color. i.e. #4e8cff",
                    );
                }
            }

            if (settings.lang && !["en", "es"].includes(settings.lang)) {
                throw new Error("Invalid lang. Options are ['en', 'es'].");
            }

            if (typeof settings.enable_http_endpoint_thread_join_patient !== "boolean") {
                throw new Error(
                    "Unable to get the value of the model setting enable_http_endpoint_thread_join_patient",
                );
            }

            if (typeof settings.enable_http_endpoint_thread_leave_patient !== "boolean") {
                throw new Error(
                    "Unable to get the value of the model setting enable_http_endpoint_thread_leave_patient",
                );
            }

            if (typeof settings.enable_http_endpoint_view_chat_patient !== "boolean") {
                throw new Error(
                    "Unable to get the value of the model setting enable_http_endpoint_view_chat_patient",
                );
            }

            if (typeof settings.enable_http_endpoint_loaded_history_patient !== "boolean") {
                throw new Error(
                    "Unable to get the value of the model setting enable_http_endpoint_loaded_history_patient",
                );
            }

            if (typeof settings.enable_http_endpoint_submit_chat_patient !== "boolean") {
                throw new Error(
                    "Unable to get the value of the model setting enable_http_endpoint_submit_chat_patient",
                );
            }

            if (typeof settings.enable_http_endpoint_user_typing_patient !== "boolean") {
                throw new Error(
                    "Unable to get the value of the model setting enable_http_endpoint_user_typing_patient",
                );
            }

            // Return settings with default
            return {
                enableNotifications: true,
                phoneNumber: "",
                primaryColor: "#4e8cff",
                windowRight: false,
                showLauncher: true,
                ...settings,
            };
        })
        .catch((error) => {
            // network error (no response) - just set reasonable defaults
            settings.enable_http_endpoint_thread_join_patient = false;
            settings.enable_http_endpoint_thread_leave_patient = false;
            settings.enable_http_endpoint_view_chat_patient = false;
            settings.enable_http_endpoint_loaded_history_patient = false;
            settings.enable_http_endpoint_submit_chat_patient = false;
            settings.enable_http_endpoint_user_typing_patient = false;

            return {
                enableNotifications: true,
                phoneNumber: "",
                primaryColor: "#4e8cff",
                windowRight: false,
                showLauncher: true,
                ...settings,
            };
        });
}
