// @flow
import "./widget.css";

import * as React from "react";
import Launcher from "./launcher";
import ChatWindow from "./chat_window";
import AttractMessage from "./attract_message";
import axios from "axios";

import { MessageState } from "../enums";
import { transformMessages } from "../utils/messages";
import { WebSocketBridge } from "../utils/websocket";
import { getCookie, setCookie, deleteCookie } from "../utils/cookie";
import throttle from "../utils/throttle";
import type {
    ApiShowMessage,
    AttractMessageType,
    AuthMessage,
    AuthResponse,
    Message,
    SentMessage,
    SessionInfo,
    WebsocketChatMessage,
    WebsocketChatMessageAck,
    WebsocketChatMessageMessage,
    WebsocketChatTyping,
    WindowState,
    FeedbackResponse,
    FullMessage,
} from "../types";
import { WindowStateTypes } from "../types";
import type { Bridge } from "../utils/websocket";
import { hexToRgb } from "../utils/color";
import { ApiHandler } from "../api";
import { withTranslation } from "react-i18next";
import {
    saveSessionId,
    getSessionInfo,
    getSessionId,
    getSessionConfig,
} from "../services/session_id";

function serializeBody(body: any) {
    return Object.keys(body)
        .map((key) => {
            let values = body[key];
            if (!Array.isArray(values)) {
                values = [values];
            }
            return values.map((value: any) => encodeURI(key) + "=" + encodeURI(value)).join("&");
        })
        .join("&");
}

function axiosPostRequest(endpoint: string, payload: any, isJsonRequest: boolean = false) {
    const token = getSessionConfig("csrf_token");
    payload["csrfmiddlewaretoken"] = token;
    const body = isJsonRequest ? payload : serializeBody(payload);
    const contentType = isJsonRequest
        ? "application/json"
        : "application/x-www-form-urlencoded; charset=UTF-8";

    return axios
        .post(endpoint, body, {
            headers: {
                "X-CSRFToken": token,
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": contentType,
            },
        })
        .catch((error) => {
            // network error (no response) - just log error
            console.log(error);
        });
}

type State = {
    isOpen: boolean,
    messages: Array<Message>,
    focusInput: boolean,
    connected: boolean,
    windowState: WindowState,
    remoteUserTyping: boolean,
    newMessages: number,
    attractMessage: AttractMessageType | null,
};
type OwnProps = {
    chatDomain: string,
    websocketUrl: string,
    headerText: string,
    primaryColor: string,
    showLauncher: boolean,
    windowRight: boolean,
    phoneNumber: string,
    siteSection?: string,
    csrfToken: string,
    chat_auth__skip_auth_enabled: boolean,
    enable_http_endpoint_thread_join_patient: boolean,
    enable_http_endpoint_thread_leave_patient: boolean,
    enable_http_endpoint_view_chat_patient: boolean,
    enable_http_endpoint_loaded_history_patient: boolean,
    enable_http_endpoint_submit_chat_patient: boolean,
    enable_http_endpoint_user_typing_patient: boolean,
};
type Props = OwnProps & {
    t: (string) => string,
};

const AUTH_SOCIAL_DOB = "AUTH_SOCIAL_DOB";
const AUTH_DOB_ONLY = "AUTH_DOB_ONLY";
const AUTH_ACCESS_CODE_DOB = "AUTH_ACCESS_CODE_DOB";
const AUTH_BILL_ID_DOB = "AUTH_BILL_ID_DOB";
const AUTH_ACCOUNT_NUM_ZIP_DOB = "AUTH_ACCOUNT_NUM_ZIP_DOB";
const AUTH_EMAIL_TOKEN = "AUTH_EMAIL_TOKEN";
const AUTH_CELLPHONE_TOKEN = "AUTH_CELLPHONE_TOKEN";
const AUTH_HOMEPHONE_TOKEN = "AUTH_HOMEPHONE_TOKEN";
const AUTH_EMAIL_DOB = "AUTH_EMAIL_DOB";
const AUTH_CELLPHONE_DOB = "AUTH_CELLPHONE_DOB";
const AUTH_TRY_ANOTHER = "AUTH_TRY_ANOTHER";
const AUTH_SKIP = "AUTH_SKIP";
const AUTH_RESEND_TOKEN = "AUTH_RESEND_TOKEN";

const FEEDBACK_COOKIE_NAME = "chatFdbk";
const OPEN_COOKIE_NAME = "chatOpen";
const ENDPOINT_LOADED_HISTORY = "/chat/loaded-history/";
const ENDPOINT_SUBMIT_CHAT = "/chat/message/";
const ENDPOINT_THREAD_JOIN = "/chat/join-thread/";
const ENDPOINT_TYPING = "/chat/typing/";

class Widget extends React.Component<Props, State> {
    sessionId: string | null;

    // This value is used for obfuscating the sessionId inside the cookie. See session_id.js
    sessionNonce: string | null;
    bridge: Bridge | null;
    ackMap: { [string]: string };
    replyMap: { [string]: Message };
    tempId: number;
    allMessages: Array<Message>;
    sentMessages: { [string]: TimeoutID };
    disconnectTimes: Array<number>;
    usersTyping: {
        [string]: {
            name: string,
            timeoutId: TimeoutID,
        },
    };
    handleOpen: Function;
    handleClose: Function;
    sendMessage: Function;
    handleInputFocused: Function;
    handleTyping: Function;
    launchWindow: Function;
    handleApi: Function;
    handleSocketOpen: Function;
    handleSocketClose: Function;
    handleSocketMessage: Function;
    handleMessage: Function;
    sendFeedback: Function;
    sendAuthResponse: Function;
    handleActiveThrottled: Function;
    pingTimer: IntervalID | null;

    constructor() {
        super();
        this.ackMap = {};
        this.replyMap = {};
        this.tempId = 0;
        this.allMessages = [];
        this.sentMessages = {};
        this.usersTyping = {};
        this.sessionId = null;
        this.sessionNonce = null;
        this.disconnectTimes = [];
        this.pingTimer = null;

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handleInputFocused = this.handleInputFocused.bind(this);
        this.handleTyping = this.handleTyping.bind(this);
        this.launchWindow = this.launchWindow.bind(this);
        this.handleApi = this.handleApi.bind(this);
        this.handleSocketOpen = this.handleSocketOpen.bind(this);
        this.handleSocketClose = this.handleSocketClose.bind(this);
        this.handleSocketMessage = this.handleSocketMessage.bind(this);
        this.sendFeedback = this.sendFeedback.bind(this);
        this.sendAuthResponse = this.sendAuthResponse.bind(this);

        // Create a throttled function, so we don't waste CPU resources
        this.handleActiveThrottled = throttle(
            () => saveSessionId(this.sessionId, this.sessionNonce),
            10000,
        );

        this.state = {
            isOpen: false,
            messages: [],
            focusInput: false,
            connected: false,
            windowState: WindowStateTypes.NONE,
            remoteUserTyping: false,
            newMessages: 0,
            attractMessage: null,
        };
    }

    componentDidMount() {
        ApiHandler.addApiListener(this.handleApi);

        if (document.location.search.indexOf("chatOpen=desktop") >= 0 && window.innerWidth < 768) {
            deleteCookie(OPEN_COOKIE_NAME);
        } else if (getCookie(OPEN_COOKIE_NAME)) {
            this.handleOpen();
        }
    }
    componentWillUnmount() {
        ApiHandler.removeApiListener();
    }

    getTempId(): string {
        this.tempId++;
        const now = new Date().getTime();
        return `${this.tempId}:${now}`;
    }

    submitChatMessage(payload) {
        if (this.props.enable_http_endpoint_submit_chat_patient) {
            if (!this.sessionId) {
                console.warn("Session is required for HTTP Chat Endpoints");
            }

            // Attach Session Credentials
            const body = {
                ...payload,
                session_id: this.sessionId,
            };

            axiosPostRequest(ENDPOINT_SUBMIT_CHAT, body, true).then((response) => {
                // the post completing successfully is the ACK
                this.handleAck(response.data);
            });
        } else {
            if (this.bridge == null) {
                console.warn("Not connected to chat. Need to initialize bridge.");
                return;
            }

            this.bridge &&
                this.bridge.sendStream("chat", {
                    TYPE: "SUBMIT_CHAT",
                    ...payload,
                });
        }
    }

    sendMessage(message: {
        text: string,
        is_quick_reply?: boolean,
        template_name?: string,
        tempId?: string,
    }) {
        const newTempId = this.getTempId();

        let lastMessage = null;
        if (this.allMessages.length > 0) {
            lastMessage = this.allMessages[this.allMessages.length - 1];
        }

        let replyMessageId = null;
        if (
            lastMessage !== null &&
            (lastMessage.type === "auth" || lastMessage.type === "full") &&
            lastMessage.message_id > 0
        ) {
            replyMessageId = lastMessage.message_id;
        }

        const messageBody = {
            in_reply_to_message_id: replyMessageId,
            is_quick_reply: message.is_quick_reply != null ? message.is_quick_reply : false,
            path: document.location.href,
            temp_id: newTempId,
            text: message.text,
            template_name: message.template_name != null ? message.template_name : null,
            from_site_section: this.props.siteSection,
        };
        this.sentMessages[newTempId] = setTimeout(
            this.updateTemporaryFailed.bind(this, newTempId),
            5000,
        );

        this.submitChatMessage(messageBody);

        this.updateMessages((messages: Array<Message>): Array<Message> => {
            let newMessages;
            if (message.tempId != null) {
                newMessages = messages.filter(
                    (m) => m.tempId == null || m.tempId !== message.tempId,
                );
            } else {
                newMessages = messages;
            }

            return newMessages.concat([
                {
                    type: "sent",
                    text: message.text,
                    date: Date.now(),
                    tempId: newTempId,
                    template_name: message.template_name != null ? message.template_name : null,
                    is_quick_reply: message.is_quick_reply != null ? message.is_quick_reply : false,
                    tempState: MessageState.LOADING,
                    in_reply_to_message_id: replyMessageId,
                },
            ]);
        });
    }

    sendAuthResponse(credentials: AuthResponse) {
        let message;
        let templateName = null;
        let inReplyTo = this.state.messages.find(
            (msg) => msg.type === "auth" && msg.id === credentials.inReplyToMessageID,
        );
        if (inReplyTo && inReplyTo.type === "auth") {
            inReplyTo.auth_credentials = credentials;
            inReplyTo.auth_status = "pending";
        }
        switch (credentials.type) {
            case AUTH_SOCIAL_DOB:
                message = `SSN: ${credentials.socialLast4}; DOB: ${credentials.dateOfBirth}`;
                break;
            case AUTH_EMAIL_TOKEN:
                message = `EMAIL_TOKEN: ${credentials.token}`;
                break;
            case AUTH_CELLPHONE_TOKEN:
                message = `CELLPHONE_TOKEN: ${credentials.token}`;
                break;
            case AUTH_HOMEPHONE_TOKEN:
                message = `HOMEPHONE_TOKEN: ${credentials.token}`;
                break;
            case AUTH_DOB_ONLY:
                message = `DOB: ${credentials.dateOfBirth}`;
                break;
            case AUTH_ACCESS_CODE_DOB:
                message = `ACCESS_CODE: ${credentials.accessCode}; DOB: ${credentials.dateOfBirth}`;
                break;
            case AUTH_ACCOUNT_NUM_ZIP_DOB:
                message = `ACCOUNT: ${credentials.accountNumber}; ZIP: ${credentials.zipCode}; DOB: ${credentials.dateOfBirth}`;
                break;
            case AUTH_BILL_ID_DOB:
                message = `BILL_ID: ${credentials.billID}; DOB: ${credentials.dateOfBirth}`;
                break;
            case AUTH_TRY_ANOTHER:
                message = "Try another method";
                templateName = "try_another";
                break;
            case AUTH_SKIP:
                message = "Skip authentication";
                templateName = "skip_auth";
                break;
            case AUTH_RESEND_TOKEN:
                message = "Resend token";
                templateName = "resend_token";
                break;
            case AUTH_EMAIL_DOB:
                message = `EMAIL: ${credentials.email}; DOB: ${credentials.dateOfBirth}`;
                break;
            case AUTH_CELLPHONE_DOB:
                message = `CELL: ${credentials.cellPhone}; DOB: ${credentials.dateOfBirth}`;
                break;
            default:
                message = "Authorization error";
        }

        const newTempId = this.getTempId();
        const messageBody = {
            TYPE: "SUBMIT_CHAT",
            in_reply_to_message_id: Number(credentials.inReplyToMessageID),
            is_quick_reply: false,
            path: document.location.href,
            temp_id: newTempId,
            text: message,
            template_name: templateName,
            from_site_section: this.props.siteSection,
        };
        this.sentMessages[newTempId] = setTimeout(
            this.updateTemporaryFailed.bind(this, newTempId),
            5000,
        );
        this.submitChatMessage(messageBody);
    }

    sendFeedback(feedback: FeedbackResponse) {
        const { t } = this.props;

        this.updateMessages((messages: Array<Message>): Array<Message> => {
            return messages.map((message) => {
                if (message.type === "feedback") {
                    // Set IDs to negative values, so they don't clash with real Ids
                    return {
                        type: "full",
                        date: Date.now(),
                        id: -1,
                        in_reply_to_message_id: null,
                        is_quick_reply: false,
                        is_staff: true,
                        message_id: -1,
                        quick_replies: [],
                        repliable_type: "FREE_TEXT",
                        repliable_via_text: true,
                        template_name: null,
                        text: feedback.needsMet
                            ? t("thank_you_for_your_feedback")
                            : t("were_sorry_your_needs_werent_met"),
                        user_id: -1,
                        user_name: "",
                        is_bot: true,
                    };
                }
                return message;
            });
        });
        // If we already sent feedback, don't send it again
        if (getCookie(FEEDBACK_COOKIE_NAME)) {
            return;
        }

        const formData = new FormData();
        formData.append("rating", String(feedback.rating));
        formData.append("needs_met", String(feedback.needsMet));
        formData.append("comments", feedback.comments);

        if (this.sessionId == null) {
            // This should never occur, but it allows FlowType to not have an issue
            throw new Error("sessionId is not found.");
        }
        formData.append("session_public_id", this.sessionId);
        fetch(`https://${this.props.chatDomain}/chat-feedback/`, {
            method: "POST",
            credentials: "include",
            body: formData,
        });

        // Save a cookie, so we don't send feedback multiple times. Only send feedback once per day
        setCookie(FEEDBACK_COOKIE_NAME, "1", 86400);
    }
    updateTemporaryFailed(tempId: number) {
        this.updateMessages((messages: Array<Message>) =>
            messages.map((message: Message): Message => {
                if (message.tempId != null && message.tempId === tempId) {
                    // $FlowFixMe Flow can't detect this as a refinement of message type
                    return {
                        ...message,
                        tempState: MessageState.FAILED,
                    };
                }

                return message;
            }),
        );
    }

    updateMessages(mapFn: (messages: Array<Message>) => Array<Message>) {
        this.allMessages = mapFn(this.allMessages);
        this.setState({
            messages: transformMessages(this.allMessages),
        });
    }

    handleAck(ackMessage: WebsocketChatMessageAck) {
        const tempIdStr = String(ackMessage.temp_id);

        if (this.sentMessages[tempIdStr]) {
            // TODO -- this timeout stuff is not relevant if
            // the message is POSTed and not websocket
            clearTimeout(this.sentMessages[tempIdStr]);
            delete this.sentMessages[tempIdStr];
            this.ackMap[String(ackMessage.id)] = ackMessage.temp_id;
            if (ackMessage.temp_id.indexOf(":") >= 0) {
                const now = new Date().getTime();
                const sent = Number(ackMessage.temp_id.split(":")[1]);
                const elapsed = now - sent;

                this.bridge &&
                    this.bridge.sendStream("login", {
                        TYPE: "PING",
                        provider_id: getSessionConfig("provider_id"),
                        ping_timestamp: new Date().toISOString(),
                        last_elapsed: elapsed / 1000,
                    });
            }

            this.updateMessages((messages: Array<Message>) =>
                messages
                    .map((message: Message): Message => {
                        if (message.tempId != null && message.tempId === ackMessage.temp_id) {
                            // $FlowFixMe Flow can't detect this as a refinement of message type
                            return {
                                ...message,
                                id: ackMessage.id,
                                tempState: MessageState.ACKED,
                            };
                        }

                        return message;
                    })
                    .filter((message: Message): boolean => {
                        if (message.id == ackMessage.id && !message.tempId) {
                            return false;
                        } else {
                            return true;
                        }
                    }),
            );
        }
    }
    handleApi(functionString: string, args: Array<any>) {
        switch (functionString) {
            case "open":
                this.handleOpen();
                break;
            case "close":
                this.handleClose();
                break;
            case "showMessage":
                this.showAttractMessage(...args);
                break;
            default:
                throw new Error(
                    `Invalid function ${functionString}. Please check the Cedar chat docs.`,
                );
        }
    }

    showAttractMessage(originalAttractMessage: ApiShowMessage) {
        this.setState({
            attractMessage: originalAttractMessage,
        });
    }

    handleAuthReply(promptMessage: AuthMessage) {
        if (promptMessage.auth_status == "verified") {
            const creds = promptMessage.auth_credentials;
            let formData = new FormData();

            formData.append("csrfmiddlewaretoken", this.props.csrfToken);

            if (creds && creds.type === AUTH_SOCIAL_DOB) {
                formData.append("type", "ssn");
                formData.append("ssn", creds.socialLast4);
                formData.append("dob", creds.dateOfBirth);
            } else if (creds && creds.type === AUTH_DOB_ONLY) {
                formData.append("type", "dob");
                formData.append("dob", creds.dateOfBirth);
            } else if (creds && creds.type === AUTH_ACCESS_CODE_DOB) {
                formData.append("type", "access_code");
                formData.append("access_code", creds.accessCode);
                formData.append("dob", creds.dateOfBirth);
            } else if (creds && creds.type === AUTH_EMAIL_TOKEN) {
                formData.append("type", "email");
                formData.append("code", creds.token);
            } else if (creds && creds.type === AUTH_CELLPHONE_TOKEN) {
                formData.append("type", "phone_cell_message");
                formData.append("code", creds.token);
            } else if (creds && creds.type === AUTH_HOMEPHONE_TOKEN) {
                formData.append("type", "phone_home_message");
                formData.append("code", creds.token);
            } else if (creds && creds.type === AUTH_BILL_ID_DOB) {
                formData.append("link_id", creds.billID);
                formData.append("dob", creds.dateOfBirth);
            } else if (creds && creds.type === AUTH_ACCOUNT_NUM_ZIP_DOB) {
                formData.append("find_type", "account");
                formData.append("dob", creds.dateOfBirth);
                formData.append("account_number", creds.accountNumber);
                formData.append("zip_code", creds.zipCode);
            } else {
                return;
            }

            let urlPath;
            let needAssign = false;

            if (creds && creds.type === AUTH_BILL_ID_DOB) {
                urlPath = "/";
                needAssign = true;
            } else if (creds && creds.type === AUTH_ACCOUNT_NUM_ZIP_DOB) {
                urlPath = "/find-account/";
                needAssign = true;
            } else if (promptMessage.auth_user_id) {
                urlPath = `/verify/?user=${promptMessage.auth_user_id}`;
            } else {
                return;
            }

            let response = fetch(`https://${this.props.chatDomain}${urlPath}`, {
                method: "POST",
                credentials: "include",
                body: formData,
            }).then((response) => {
                if (response.status === 200 || response.status === 302) {
                    if (needAssign && promptMessage.auth_user_id) {
                        window.location.assign(`/home/${promptMessage.auth_user_id}/?chat`);
                    } else {
                        // NOTE: If the current page is only accessible to guests, authenticated requests will be redirected to a corresponding page by Django.
                        window.location.assign(window.location.href);
                    }
                }
            });
        }
    }

    handleMessage(newMessage: WebsocketChatMessageMessage) {
        const ackedTempId = this.ackMap[String(newMessage.id)];
        const newTempId = newMessage.temp_id;

        const messageWithoutTypeProp = {
            type: "full",
            date: newMessage.date,
            id: newMessage.id,
            in_reply_to_message_id: newMessage.in_reply_to_message_id,
            is_quick_reply: newMessage.is_quick_reply,
            is_staff: newMessage.is_staff,
            message_id: newMessage.message_id,
            quick_replies: newMessage.quick_replies,
            repliable_type: newMessage.repliable_type,
            repliable_via_text: newMessage.repliable_via_text,
            template_name: newMessage.template_name,
            text: newMessage.text,
            user_id: newMessage.user_id,
            user_name: newMessage.user_name,
            is_bot: newMessage.is_bot,
            tempId: newMessage.temp_id,
        };

        if (localStorage.getItem("lastChatTimestamp") !== null) {
            localStorage.setItem("lastChatTimestamp", Date.now().toString());
        }

        // message that was sent and has been ACKed
        if (ackedTempId) {
            this.updateMessages((messages) =>
                messages.map((message) => {
                    if (message.tempId && message.tempId === ackedTempId) {
                        return messageWithoutTypeProp;
                    }
                    return message;
                }),
            );
        } else {
            this.updateMessages((messages) => {
                let messageUpdated = false;
                const newMessages = messages.map((message) => {
                    // this is a message we sent but the ACK hasn't come yet
                    if (
                        message.tempId === newTempId &&
                        message.tempState === MessageState.LOADING
                    ) {
                        messageUpdated = true;
                        return messageWithoutTypeProp;
                    }
                    return message;
                });
                if (messageUpdated) {
                    return newMessages;
                } else {
                    return newMessages.concat([messageWithoutTypeProp]);
                }
            });
        }

        // Clear the typing if a message comes in from staff
        if (newMessage.is_staff) {
            this.clearUsersTyping();
        }

        if (newMessage.in_reply_to_message_id) {
            const stateMessage = this.state.messages.find(
                (msg) => msg.type === "auth" && msg.id === newMessage.in_reply_to_message_id,
            );
            if (stateMessage && stateMessage.type === "auth") {
                this.handleAuthReply(stateMessage);
            }
        }

        if (this.state.isOpen) {
            this.setState({
                focusInput: true,
            });
        } else {
            this.setState({
                newMessages: this.state.newMessages + 1,
            });
        }
    }

    clearUsersTyping() {
        Object.keys(this.usersTyping).forEach((key) => {
            clearTimeout(this.usersTyping[key].timeoutId);
        });
        this.usersTyping = {};
        this.checkRemoteUserTyping();
    }

    handleRemoteUserTyping(message: WebsocketChatTyping) {
        // Ignore typing from users that aren't staff
        if (!message.is_staff) {
            return;
        }

        const userStr = String(message.user_id);
        if (this.usersTyping[userStr]) {
            clearTimeout(this.usersTyping[userStr].timeoutId);
        }

        this.usersTyping[userStr] = {
            name: message.user_name,
            timeoutId: setTimeout(() => {
                delete this.usersTyping[userStr];
                this.checkRemoteUserTyping();
            }, 8000),
        };
        this.checkRemoteUserTyping();
    }

    handleClosedThread() {
        if (getCookie(FEEDBACK_COOKIE_NAME) == null) {
            this.updateMessages((messages: Array<Message>): Array<Message> => {
                return messages.concat([
                    {
                        type: "feedback",
                        date: Date.now(),
                    },
                ]);
            });
        }
    }

    checkRemoteUserTyping() {
        const remoteUserTyping = Object.keys(this.usersTyping).length > 0;
        if (this.state.remoteUserTyping !== remoteUserTyping) {
            this.setState({
                remoteUserTyping,
            });
        }
    }
    handleTyping() {
        // Flow knows this can be null. This should never occur
        if (this.bridge == null) {
            console.warn("Not connected to chat. Need to initialize bridge.");
            return;
        }

        if (this.props.enable_http_endpoint_user_typing_patient) {
            if (!this.sessionId) {
                console.warn("Session is required for HTTP Chat Endpoints");
            }

            axiosPostRequest(ENDPOINT_TYPING, { session_id: this.sessionId }, true);
        } else {
            this.bridge.sendStream("chat", { TYPE: "USER_TYPING" });
        }
    }
    launchWindow() {
        // If we are loading or ready, don't enter loading again
        if ([WindowStateTypes.READY, WindowStateTypes.LOADING].includes(this.state.windowState)) {
            return;
        }

        this.setState({
            windowState: WindowStateTypes.LOADING,
        });

        let prom: Promise<{ sessionId: string, sessionNonce: string }>;
        if (this.sessionId && this.sessionNonce) {
            prom = getSessionInfo(this.props.chatDomain, this.sessionId).then((sessionInfo) =>
                Promise.resolve({
                    sessionId: this.sessionId,
                    sessionNonce: this.sessionNonce,
                }),
            );
        } else {
            prom = getSessionId(this.props.chatDomain);
        }

        prom.then((sessionInfo: SessionInfo) => {
            this.sessionId = sessionInfo.sessionId;
            this.sessionNonce = sessionInfo.sessionNonce;
            saveSessionId(this.sessionId, this.sessionNonce);
            this.connectWebsocket(sessionInfo.sessionId);
            ApiHandler.trigger("open");
        }).catch(() => {
            this.setState({
                windowState: WindowStateTypes.ERROR,
            });
            ApiHandler.trigger("open");
        });
    }
    setError() {
        // Force the socket close so we can create another connection
        this.closeWebsocket();
        this.setState({
            windowState: WindowStateTypes.ERROR,
        });
    }
    connectWebsocket(sessionId: string) {
        if (this.bridge) {
            this.bridge.close();
        }
        const wssUrl = `${this.props.websocketUrl}/${sessionId}/`;

        this.bridge = WebSocketBridge(wssUrl, {
            maxReconnectionDelay: 10000,
            minReconnectionDelay: 3000,
        });
        this.bridge.listenStream("chat", this.handleSocketMessage);

        if (this.bridge != null) {
            this.bridge.addEventListener("close", this.handleSocketClose);
        }

        if (this.bridge != null) {
            this.bridge.addEventListener("open", this.handleSocketOpen);
        }
    }
    handleSocketMessage(message: WebsocketChatMessage) {
        switch (message.TYPE) {
            case "ACK_CHAT":
                return this.handleAck(message);
            case "CHAT_MESSAGE":
                return this.handleMessage(message);
            case "USER_TYPING":
                return this.handleRemoteUserTyping(message);
            case "CLOSED_THREAD":
                if (message.chat_reasons && message.chat_reasons[0] == "auto_verify") {
                    return;
                }
                return this.handleClosedThread();
        }
    }
    handleSocketOpen() {
        // Flow knows this could be null. This should never happen.
        if (this.bridge == null) {
            console.warn("Not connected to chat. Need to initialize bridge.");
            return;
        }

        // Join the thread and load all the messages
        if (this.props.enable_http_endpoint_thread_join_patient) {
            // JOIN_GROUP is transitional until the new websocket server
            this.bridge.sendStream("chat", { TYPE: "JOIN_GROUP" });

            if (!this.sessionId) {
                console.warn("Session is required for HTTP Chat Endpoints");
            }

            axiosPostRequest(ENDPOINT_THREAD_JOIN, { session_id: this.sessionId }, true);
        } else {
            this.bridge.sendStream("chat", { TYPE: "JOIN_THREAD" });
        }
        this.setState({
            connected: true,
        });

        this.loadMessagesAndInit();

        this.pingTimer = setInterval(() => {
            this.bridge &&
                this.bridge.sendStream("login", {
                    TYPE: "PING",
                    provider_id: getSessionConfig("provider_id"),
                    ping_timestamp: new Date().toISOString(),
                    last_elapsed: null,
                });
        }, 30000);
    }
    handleSocketClose() {
        this.setState({
            connected: false,
        });

        if (this.pingTimer) {
            clearInterval(this.pingTimer);
            this.pingTimer = null;
        }

        const currentTime = Date.now();
        this.disconnectTimes.push(Date.now());
        this.disconnectTimes = this.disconnectTimes.slice(-3);

        // Declare connection failed if we fail 3 times in 30 seconds
        const failed =
            this.disconnectTimes.length === 3 &&
            this.disconnectTimes.every((time) => time >= currentTime - 30000);

        if (failed) {
            this.disconnectTimes = [];
            this.setError();
        }
    }

    closeWebsocket() {
        if (this.bridge) {
            this.bridge.removeEventListener("close", this.handleSocketClose);
        }

        if (this.bridge) {
            this.bridge.removeEventListener("open", this.handleSocketOpen);
        }

        if (this.bridge) {
            this.bridge.close();
        }

        this.bridge = null;
    }
    loadMessagesAndInit() {
        this.getMessages()
            .then((messages: Array<Message>) => {
                // Flow knows this could be null. This should never happen.
                if (this.bridge == null) {
                    console.warn("Not connected to chat. Need to initialize bridge.");
                    return;
                }

                // Tell the backend history is loaded, so the bot will send a message
                if (this.props.enable_http_endpoint_loaded_history_patient) {
                    if (!this.sessionId) {
                        console.warn("Session is required for HTTP Chat Endpoints");
                    }

                    axiosPostRequest(ENDPOINT_LOADED_HISTORY, { session_id: this.sessionId }, true);
                } else {
                    this.bridge.sendStream("chat", { TYPE: "LOADED_HISTORY" });
                }

                // Replace all messages
                this.updateMessages(() => messages);

                // Make sure we are still connected before showing everything
                if (this.state.windowState === WindowStateTypes.LOADING && this.state.connected) {
                    this.setState({
                        windowState: WindowStateTypes.READY,
                    });
                }
            })
            .catch((ex) => {
                this.setState({
                    windowState: WindowStateTypes.ERROR,
                });

                return Promise.reject(ex);
            });
    }

    /**
     * Get all the messages from the server
     */
    getMessages(): Promise<Array<Message>> {
        // This code should never occur. We should always fetch the session before this.
        if (this.sessionId == null) {
            throw new Error("sessionId is not found.");
        }

        return fetch(`https://${this.props.chatDomain}/history/chat/${this.sessionId}/1/`, {
            credentials: "include",
        })
            .then((response) => response.json())
            .then((json: { results: Array<FullMessage> }): Array<Message> => {
                if (!json.results) {
                    throw new Error("Server error");
                }

                return json.results.map((message) => ({
                    ...message,
                    type: "full",
                }));
            });
    }
    getUserTypingNames() {
        return Object.keys(this.usersTyping).map((userId) => this.usersTyping[userId].name);
    }
    /**
     * This is a trick to focus the element on demand. You set focusInput, then it will get unset here
     */
    handleInputFocused() {
        this.setState({
            focusInput: false,
        });
    }

    handleClose() {
        if (!this.state.isOpen) {
            return;
        }

        this.setState({
            isOpen: false,
            newMessages: 0,
            attractMessage: null,
        });
        // Delete the cookie
        deleteCookie(OPEN_COOKIE_NAME);

        // Remove activity listener
        ["mousemove", "click", "keydown", "scroll"].forEach((event) => {
            document.removeEventListener(event, this.handleActiveThrottled);
        });

        ApiHandler.trigger("close");
    }

    /**
     * Handle clicking the launcher. Open up the window and load everything
     */
    handleOpen() {
        if (this.state.isOpen) {
            return;
        }
        // close Help Center if it's open
        if (typeof window.helpCenterWidget !== "undefined") {
            window.helpCenterWidget.close();
        }

        this.setState({
            isOpen: true,
            newMessages: 0,
            attractMessage: null,
        });
        const ONE_DAY = 86400;
        setCookie(OPEN_COOKIE_NAME, "1", ONE_DAY);
        this.launchWindow();

        // Add activity listener
        ["mousemove", "click", "keydown", "scroll"].forEach((event) => {
            document.addEventListener(event, this.handleActiveThrottled, { passive: true });
        });
    }

    render() {
        const { showLauncher } = this.props;
        return (
            <div id="cedar-chat">
                {this.state.isOpen && <div className="gradient" />}
                {!this.state.isOpen && this.state.attractMessage && (
                    <AttractMessage
                        headerText={this.props.headerText}
                        attractMessage={this.state.attractMessage}
                    />
                )}
                <ChatWindow
                    onUserInputSubmit={this.sendMessage}
                    onClose={this.handleClose}
                    onInputFocused={this.handleInputFocused}
                    onTyping={this.handleTyping}
                    onRestart={this.launchWindow}
                    onFeedbackSubmit={this.sendFeedback}
                    onAuthSubmit={this.sendAuthResponse}
                    messages={this.state.messages}
                    state={this.state.windowState}
                    isOpen={this.state.isOpen}
                    connected={this.state.connected}
                    focusInput={this.state.focusInput}
                    usersTyping={this.state.remoteUserTyping ? this.getUserTypingNames() : null}
                    agentProfile={{
                        teamName: this.props.headerText,
                        imageUrl: "",
                        phoneNumber: this.props.phoneNumber,
                    }}
                    windowRight={this.props.windowRight}
                    skipAuthEnabled={this.props.chat_auth__skip_auth_enabled}
                />
                {showLauncher && (
                    <Launcher
                        newMessagesCount={this.state.newMessages}
                        onOpen={this.handleOpen}
                        onClose={this.handleClose}
                        isOpen={this.state.isOpen || this.state.attractMessage != null}
                    />
                )}
            </div>
        );
    }
}

Widget.displayName = "Widget";

const translatedComponent: React.AbstractComponent<OwnProps> = withTranslation()(Widget);
export default translatedComponent;
