// @flow
import * as React from "react";
import debounce from "lodash/debounce";
import CheckboxButton from "../checkbox_button";
import type { QuickReply as QuickReplyType } from "../../types";

import type { QuickReplyClickDetails } from "../../types";

type Props = {
    quickReply: QuickReplyType,
    onClick: (quickReplyDetails: QuickReplyClickDetails) => void,
};

function QuickReply(props: Props): React.Node {
    const { quickReply } = props;
    const itemClassString = (quickReply.classes || []).join(" ");
    const noSelect = quickReply.classes && quickReply.classes.indexOf("noselect") >= 0;

    const debouncedClickHandler = React.useCallback(
        debounce(
            () => {
                if (quickReply.selected != null) {
                    return;
                }

                if (noSelect) {
                    return;
                }

                props.onClick({
                    text: quickReply.display_text,
                    is_quick_reply: true,
                    template_name: quickReply.template_name,
                });
            },
            3000,
            { leading: true, trailing: false, maxWait: 3000 },
        ),
        [quickReply],
    );

    return (
        <CheckboxButton
            className={itemClassString}
            renderer={quickReply.renderer || "text"}
            onClick={debouncedClickHandler}
            disabled={!noSelect && (quickReply.selected != null || quickReply.disabled)}
            selected={quickReply.selected === true}
            text={quickReply.display_text}
        />
    );
}

QuickReply.displayName = "QuickReply";

export default QuickReply;
