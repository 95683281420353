// @flow

import "./attract_message.css";
import * as React from "react";
import { Component } from "react";
import type { AttractMessageType } from "../types";

type Props = {
    attractMessage: AttractMessageType,
    headerText: string,
};

class AttractMessage extends Component<Props> {
    renderButtons(): Array<React.Node> {
        return this.props.attractMessage.replies.map((reply) => (
            <button
                className="button"
                key={reply.display}
                disabled={false}
                selected={false}
                onClick={reply.onClick}
            >
                {reply.display}
            </button>
        ));
    }
    render(): React.Node {
        return (
            <div className="attract-message">
                <div className="attract-message--header">{this.props.headerText}</div>
                <div className="attract-message--body">{this.props.attractMessage.text}</div>
                <div className="attract-message--buttons">{this.renderButtons()}</div>
            </div>
        );
    }
}

AttractMessage.displayName = "AttractMessage";

export default AttractMessage;
