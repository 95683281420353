// @flow
import "./feedback_form.css";

import { Component } from "react";
import * as React from "react";
import { withTranslation } from "react-i18next";
import type { FeedbackResponse } from "../../types";

type OwnProps = {
    onSubmit: (FeedbackResponse) => void,
};

type Props = OwnProps & {
    t: (string) => string,
};

type State = {
    hoverValue: number,
    rating: number,
    needsMet: null | boolean,
    comments: string,
};
type SyntheticEventHandler = (event: SyntheticEvent<HTMLElement>) => mixed;

class FeedbackForm extends Component<Props, State> {
    handleStarOff: SyntheticEventHandler;
    handleStarOn: SyntheticEventHandler;
    handleStarClick: SyntheticEventHandler;
    handleNeedsMetChange: SyntheticEventHandler;
    handleCommentsChange: SyntheticEventHandler;
    handleSubmit: SyntheticEventHandler;

    constructor() {
        super();
        this.state = {
            hoverValue: 0,
            rating: 0,
            needsMet: null,
            comments: "",
        };

        this.handleStarOff = this.handleStarOff.bind(this);
        this.handleStarOn = this.handleStarOn.bind(this);
        this.handleStarClick = this.handleStarClick.bind(this);
        this.handleNeedsMetChange = this.handleNeedsMetChange.bind(this);
        this.handleCommentsChange = this.handleCommentsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleStarOn(e: SyntheticInputEvent<HTMLElement>): mixed {
        const hoverValue = Number(e.target.value);
        this.setState({
            hoverValue,
        });
    }

    handleStarOff(event: SyntheticInputEvent<HTMLElement>): mixed {
        this.setState({
            hoverValue: 0,
        });
    }

    handleStarClick(e: SyntheticInputEvent<HTMLElement>): mixed {
        const rating = Number(e.target.value);
        if (this.state.rating === rating) {
            // Allow click again to remove selection
            this.setState({
                rating: 0,
            });
        } else {
            this.setState({
                rating,
            });
        }
    }

    handleNeedsMetChange(e: SyntheticInputEvent<HTMLElement>): mixed {
        const value = e.target.value;
        this.setState({
            needsMet: value === "yes",
        });
    }
    handleCommentsChange(e: SyntheticInputEvent<HTMLTextAreaElement>): mixed {
        const comments = e.target.value;
        this.setState({
            comments,
        });
    }
    handleSubmit(e: SyntheticInputEvent<HTMLTextAreaElement>): mixed {
        e.preventDefault();

        const { comments, needsMet, rating } = this.state;
        this.props.onSubmit({
            comments,
            needsMet,
            rating,
        });
    }
    formValid() {
        return this.state.rating > 0 && this.state.needsMet !== null;
    }

    renderStars() {
        return [1, 2, 3, 4, 5].map((starValue) => (
            <button
                type="button"
                key={starValue}
                value={String(starValue)}
                aria-label={`${starValue} stars`}
                className={`feedback--star ${
                    starValue <= this.state.hoverValue || starValue <= this.state.rating
                        ? "feedback--start-select"
                        : ""
                }`}
                onMouseEnter={this.handleStarOn}
                onMouseLeave={this.handleStarOff}
                onFocus={this.handleStarOn}
                onBlur={this.handleStarOff}
                onClick={this.handleStarClick}
            >
                ★
            </button>
        ));
    }
    render() {
        const { t } = this.props;

        return (
            <form className="feedback" onSubmit={this.handleSubmit}>
                <div className="feedback--title">{t("how_was_your_chat_experience")}</div>
                <div className="feedback--stars">{this.renderStars()}</div>
                <div className="feedback--title">{t("were_all_of_your_needs_met")}</div>
                <div className="feedback--needs">
                    <label className="feedback--left-label block radio">
                        <input
                            className="_feedback_needs_met"
                            checked={this.state.needsMet === true}
                            onChange={this.handleNeedsMetChange}
                            type="radio"
                            value="yes"
                            name="needs_met"
                        />
                        <span />
                        {t("yes")}
                    </label>
                    <label className="radio block">
                        <input
                            className="_feedback_needs_met"
                            checked={this.state.needsMet === false}
                            onChange={this.handleNeedsMetChange}
                            type="radio"
                            value="no"
                            name="needs_met"
                        />
                        <span />
                        {t("no")}
                    </label>
                </div>
                <div className="pb2">
                    <textarea
                        value={this.state.comments}
                        onChange={this.handleCommentsChange}
                        className="feedback--textbox"
                        name="comment"
                        placeholder={
                            this.state.needsMet === false
                                ? t("what_could_we_have_done_better")
                                : t("comments")
                        }
                    />
                </div>
                <button
                    title={this.formValid() ? "" : t("please_complete_form")}
                    className="button feedback--submit"
                    disabled={!this.formValid()}
                    onClick={() => {}}
                >
                    {t("submit")}
                </button>
            </form>
        );
    }
}
FeedbackForm.displayName = "FeedbackForm";

const translatedComponent: React.AbstractComponent<OwnProps> = withTranslation()(FeedbackForm);
export default translatedComponent;
