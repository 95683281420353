import type { Message, QuickReply } from "../types";

const AUTH_VERIFIED_MESSAGE = "Auth: Verified";
const AUTH_DENIED_MESSAGE = "Auth: Denied";
const AUTH_INCORRECT_MESSAGE = "Auth: Incorrect";
const AUTH_SENT_LINK_MESSAGE = "Auth: Sent Link";
const AUTH_SENT_TOKEN_MESSAGE = "Auth: Sent Token";
const AUTH_SKIP_MESSAGE = "Skip authentication";

function transformAuthMessage(message: Message, messagesById: Object) {
    const toSkip = [];

    const authTypes = [
        "AUTH_SOCIAL_DOB",
        "AUTH_DOB_ONLY",
        "AUTH_OTP_TOKEN",
        "AUTH_ACCESS_CODE_DOB",
        "AUTH_BILL_ID_DOB",
        "AUTH_ACCOUNT_NUM_ZIP_DOB",
        "AUTH_EMAIL_TOKEN",
        "AUTH_HOMEPHONE_TOKEN",
        "AUTH_CELLPHONE_TOKEN",
        "AUTH_EMAIL_DOB",
        "AUTH_CELLPHONE_DOB",
    ];

    const authReplyInfo = {
        [AUTH_DENIED_MESSAGE]: ["denied", true],
        [AUTH_INCORRECT_MESSAGE]: ["incorrect", false],
        [AUTH_SENT_LINK_MESSAGE]: ["sent_link", false],
        [AUTH_SENT_TOKEN_MESSAGE]: ["sent_token", false],
    };

    // is this an auth prompt? if so make sure the type
    // info is correct
    if (
        message.is_bot &&
        message.repliable_type &&
        authTypes.indexOf(message.repliable_type) >= 0
    ) {
        message.type = "auth";
        message.auth_type = message.repliable_type;
        message.auth_status = "pending";
    }

    // check if this message is an auth response
    if (message.in_reply_to_message_id) {
        const inReplyTo = messagesById[message.in_reply_to_message_id];
        if (inReplyTo && inReplyTo.type === "auth") {
            switch (message.text) {
                case AUTH_DENIED_MESSAGE:
                case AUTH_INCORRECT_MESSAGE:
                case AUTH_SENT_LINK_MESSAGE:
                case AUTH_SENT_TOKEN_MESSAGE:
                    const [status, repliable] = authReplyInfo[message.text];
                    inReplyTo.auth_status = status;
                    inReplyTo.repliable_via_text = repliable;
                    break;
                case AUTH_SKIP_MESSAGE:
                    inReplyTo.auth_status = "canceled";
                    break;
                default:
                    if (message.text.startsWith(AUTH_VERIFIED_MESSAGE)) {
                        const userIdMatch = message.text.match(/\(([0-9a-f]{32})\)/);
                        if (userIdMatch) {
                            inReplyTo.auth_user_id = userIdMatch[1];
                        }
                        inReplyTo.auth_status = "verified";
                        inReplyTo.repliable_via_text = true;
                    }
            }

            toSkip.push(message.id);
        }
    }
    return toSkip;
}

/***
 * Updates quick reply responses to be a single message instead of a separate message. Marks the other quick replies
 * as not selected, if the person didn't respond
 * @param messages
 */
export function transformMessages(messages: Array<Message>) {
    let toSkip = [];
    const messagesById = messages.reduce((prev, message) => {
        prev[message.id] = message;
        return prev;
    }, {});
    return messages
        .map((message: Message, index): Message => {
            const nextMessage = messages[index + 1];
            const isLastMessage = messages.length - 1 === index;

            toSkip = toSkip.concat(transformAuthMessage(message, messagesById));

            // Check to see if the message has a quick reply, set selected quick reply
            if (
                nextMessage != null &&
                (nextMessage.type === "full" || nextMessage.type === "sent") &&
                message.type === "full" &&
                message.quick_replies != null
            ) {
                if (
                    nextMessage.is_quick_reply === true &&
                    nextMessage.in_reply_to_message_id === message.message_id
                ) {
                    const quickReplies = message.quick_replies.map(
                        (quickReply: QuickReply): QuickReply => ({
                            display_text: quickReply.display_text,
                            template_name: quickReply.template_name,
                            classes: quickReply.classes,
                            renderer: quickReply.renderer,
                            selected:
                                nextMessage.text.toUpperCase() ===
                                quickReply.display_text.toUpperCase(),
                            disabled: !isLastMessage,
                        }),
                    );

                    return {
                        ...message,
                        quick_replies: quickReplies,
                    };
                } else {
                    const quickReplies = message.quick_replies.map(
                        (quickReply: QuickReply): QuickReply => ({
                            display_text: quickReply.display_text,
                            template_name: quickReply.template_name,
                            classes: quickReply.classes,
                            renderer: quickReply.renderer,
                            selected: false,
                            disabled: !isLastMessage,
                        }),
                    );

                    return {
                        ...message,
                        quick_replies: quickReplies,
                    };
                }
            }

            return message;
        })
        .filter((message) => {
            return (
                toSkip.indexOf(message.id) < 0 &&
                (message.type === "feedback" || !message.is_quick_reply)
            );
        });
}
