// @flow
export default function throttle(
    fn: (...args: Array<any>) => any,
    intervalSeconds: number,
): (...args: Array<any>) => void {
    /**
     * Throttle function that calls function at beginning and end of interval
     */
    let intervalCalled = false;
    let callAtEnd = false;
    return function (...args): void {
        if (intervalCalled) {
            callAtEnd = true;
            return;
        }
        intervalCalled = true;
        setTimeout(fn.bind(this, ...args), 0);
        setTimeout(() => {
            intervalCalled = false;
            if (callAtEnd) {
                callAtEnd = false;
                // Call async so errors in fn don't stop throttle
                setTimeout(fn.bind(this, ...args), 0);
            }
        }, intervalSeconds);
    };
}
