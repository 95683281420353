// @flow

import "./launcher.css";
import * as React from "react";
import { withTranslation } from "react-i18next";
import openIcon from "../assets/open_icon.svg";
import closeIcon from "../assets/close_icon.png";

const MessageCount = (props: { isOpen: boolean, count: number }) => {
    if (props.count === 0 || props.isOpen === true) {
        return null;
    }
    return <div className="launcher--new-message-count">{props.count}</div>;
};

type OwnProps = {
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void,
    newMessagesCount: number,
};

type Props = OwnProps & {
    t: (string) => string,
};

class Launcher extends React.Component<Props> {
    handleClick: Function;

    constructor() {
        super();

        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        if (this.props.isOpen) {
            this.props.onClose();
        } else {
            this.props.onOpen();
        }
    }
    render() {
        const { t, isOpen } = this.props;
        const classList = ["launcher theme-background-color", isOpen ? "launcher--opened" : ""];

        return (
            <button
                aria-label="close chat"
                className={classList.join(" ")}
                onClick={this.handleClick}
            >
                <div className="launcher--img-container">
                    <img className="launcher--close-icon" src={closeIcon} />
                    <img className="launcher--open-icon" src={openIcon} />
                </div>
                {!isOpen && <span className="launcher--chat-text">{t("help")}</span>}
                <MessageCount count={this.props.newMessagesCount} isOpen={isOpen} />
            </button>
        );
    }
}
const translatedComponent: React.AbstractComponent<OwnProps> = withTranslation()(Launcher);
export default translatedComponent;
