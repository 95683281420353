// @flow

import "./message_list.css";
import type {
    Message as MessageType,
    QuickReplyClickDetails,
    FeedbackResponse,
    AuthResponse,
} from "../../types";
import { Component } from "react";
import * as React from "react";
import Message from "./message";
import Typing from "./typing";
import FeedbackForm from "./feedback_form";
import AuthForm from "./auth_form";

type Props = {
    messages: Array<MessageType>,
    usersTyping: string[] | null,
    teamName: string,
    onSendMessage: (message: QuickReplyClickDetails) => void,
    onFeedbackSubmit: (feedback: FeedbackResponse) => void,
    onAuthSubmit: (response: AuthResponse) => void,
    skipAuthEnabled: boolean,
};

const FIVE_MINUTES = 5 * 60 * 1000;

class MessageList extends Component<Props> {
    handleScrollList: Function;
    scrollList: HTMLDivElement;

    static defaultProps: {
        onAuthSubmit: (response: AuthResponse) => void,
    };

    constructor() {
        super();

        this.handleScrollList = this.handleScrollList.bind(this);
    }
    scrollBottom() {
        if (this.scrollList) {
            this.scrollList.scrollTop = this.scrollList.scrollHeight;
        }
    }
    componentDidMount() {
        this.scrollBottom();
    }
    componentDidUpdate() {
        this.scrollBottom();
    }

    handleScrollList(el: HTMLDivElement) {
        this.scrollList = el;
    }

    getMessageKey(message: MessageType, index: number): string {
        if (message.tempId != null) {
            return `temp:${message.tempId}`;
        } else if (message.id !== null) {
            return String(message.id);
        } else {
            return `idx:${index}`;
        }
    }

    render(): React.Node {
        return (
            <div data-test="message-list" className="message-list" ref={this.handleScrollList}>
                {this.props.messages.map((message: MessageType, index: number) => {
                    if (message.type === "feedback") {
                        return <FeedbackForm onSubmit={this.props.onFeedbackSubmit} />;
                    } else if (message.type === "auth") {
                        return (
                            <AuthForm
                                teamName={this.props.teamName}
                                onSubmit={this.props.onAuthSubmit}
                                promptMessage={message}
                                skipAuthEnabled={this.props.skipAuthEnabled}
                            />
                        );
                    }
                    const nextMessage = this.props.messages[index + 1];
                    const showTime = !nextMessage || nextMessage.date - FIVE_MINUTES > message.date;

                    return (
                        <Message
                            onSendMessage={this.props.onSendMessage}
                            showTime={showTime}
                            message={message}
                            teamName={this.props.teamName}
                            key={this.getMessageKey(message, index)}
                        />
                    );
                })}
                {this.props.usersTyping ? (
                    <Typing key="typing" name={this.props.usersTyping[0]} />
                ) : null}
            </div>
        );
    }
}

MessageList.displayName = "MessageList";
MessageList.defaultProps = {
    onAuthSubmit: function (response) {},
};

export default MessageList;
