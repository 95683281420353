//@flow

import ReconnectingWebSocket from "reconnecting-websocket";

type Options = {
    [key: string]: any,
    maxReconnectionDelay?: number,
    minReconnectionDelay?: number,
    reconnectionDelayGrowFactor?: number,
    connectionTimeout?: number,
    maxRetries?: number,
    debug?: boolean,
};

export type Bridge = EventTarget & {
    listenStream: (stream: string, callback: (message: any) => any) => void,
    sendStream: (stream: string, message: any) => void,
    close: () => void,
};

export function WebSocketBridge(url: string | (() => string), options: Options): Bridge {
    const socket = new ReconnectingWebSocket(url, undefined, options);
    const streamListeners = {};

    function onMessage(event) {
        const msg = JSON.parse(event.data);
        if (streamListeners[msg.stream]) {
            streamListeners[msg.stream].forEach((callback) => callback(msg.payload));
        }
    }

    socket.addEventListener("message", onMessage);
    const originalClose = socket.close.bind(socket);

    return Object.assign(socket, {
        listenStream(stream: string, callback: (message: any) => any) {
            if (!streamListeners[stream]) {
                streamListeners[stream] = [];
            }
            streamListeners[stream].push(callback);
        },
        unlistenStream(stream: string, callback: (message: any) => any) {
            if (streamListeners[stream]) {
                streamListeners[stream].filter((existingCallback) => callback !== existingCallback);
            }
        },
        connected() {
            return this.readyState === WebSocket.OPEN;
        },
        sendStream(stream: string, message: any) {
            const msg = {
                stream,
                payload: message,
            };

            // Don't send messages when not connected. This is essentially like an unacked message.
            if (this.connected()) {
                this.send(JSON.stringify(msg));
            } else {
                console.error("Attempted to send to closed socket.", msg);
            }
        },
        close() {
            socket.removeEventListener("message", onMessage);
            originalClose();
        },
    });
}
