import i18n from "../i18n";

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const month = (365 * day) / 12;
const year = 365 * day;
const times = [
    {
        min: 5 * second,
        interval: second,
        display: "second",
    },
    {
        min: minute,
        interval: minute,
        display: "minute",
    },
    {
        min: hour,
        interval: hour,
        display: "hour",
    },
    {
        min: day,
        interval: day,
        display: "day",
    },
    {
        min: month,
        interval: month,
        display: "month",
    },
    {
        min: year,
        interval: year,
        display: "year",
    },
];

export function timeAgo(referenceTime: number): string {
    const time = Date.now() - referenceTime;
    for (let i = times.length - 1; i >= 0; i--) {
        const interval = times[i];
        if (time >= interval.min) {
            const count = Math.floor(time / interval.interval);
            return i18n.t(interval.display, { count });
        }
    }
    return i18n.t("just_now");
}
